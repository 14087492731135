import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { CodeResponse } from '@react-oauth/google';

import { AsyncTask } from '../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { appRoutes } from '../../../../router/routes';
import { LANGUAGE } from '../../../../shared/enum';
import { AuthService } from '../../AuthService';
import { isAuthUserResponseDto } from '../../helpers';
import {
  OAuthType
} from '../../register/components/social-media-registration/SocialMediaRegistrationRoute';
import { IGoogleLoginButtonProps } from './GoogleLoginButton';

@transient()
export class GoogleLoginButtonVm extends ViewModel<IGoogleLoginButtonProps> {

  constructor(
    @inject(AuthService) private readonly authService: AuthService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get fromLocation(): string {
    const existingRoute = Object.values(appRoutes).find(val => val === this.props.fromLocation);
    return existingRoute ?? appRoutes.map;
  }

  public googleLogin = new AsyncTask(async (codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    try {
      /**
       * If user is not registered --> get needed data from server (server communicates with google API)
       *    - with given data, do another call to server's /api/google/auth from registration screen when user accepts Terms & Conditions
       * If user is registrated --> do login
      */
      // Check if user already exists.
      const response = await this.authService.startLoginRequest(OAuthType.Google, 'accessToken', codeResponse.code);

      if (!response) {
        throw new Error('Something went wrong');
      }

      if (response.ok && isAuthUserResponseDto(response.data)) {
        if (!response.data.userExists) {
          await this.tracking.track(TrackingEvent.REGISTRATION_STARTED, { registrationType: RegistrationVia.GOOGLE });
          return this.navigate(appRoutes.socialMediaRegister, {
            state: {
              access_token: response.data.access_token,
              email: response.data.email,
              type: OAuthType.Google,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              language: this.i18n.language as LANGUAGE,
            }
          });
        } else {
          await this.tracking.track(TrackingEvent.LOGIN_STARTED, { registrationType: RegistrationVia.GOOGLE });
          await this.authService.startLoginRequest(OAuthType.Google, response.data.access_token);
        }
      }

      await this.tracking.track(TrackingEvent.LOGIN_COMPLETED, { registrationType: RegistrationVia.GOOGLE });

      return this.navigate(this.fromLocation);
    } catch (e) {
      console.error(`unexpected exception. ${e}`);
      this.notification.error(this.i18n.t('auth:socialmedia_button.google_error'));
    }
  })

  public handleGoogleLoginError = (errorResponse: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    if (errorResponse.error_description === 'idpiframe_initialization_failed') {
      return;
    }

    this.notification.error(this.i18n.t('auth:socialmedia_button.google_failure'));
  }

}
