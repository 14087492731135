import { Box, Typography, useMediaQuery } from '@mui/material';

import { Assets } from '../../../../../assets';
import { colors } from '../../../../../theme/options/palette/const/colors';

interface NoDataTableProps {
  message: string;
}

export const NoDataTable = function NoDataTable(props: NoDataTableProps) {
  const isSmartphoneScreen = useMediaQuery('(max-width:750px)');

  return (
    <Box
      sx={{
        bgcolor: colors.paper,
        boxShadow: '0px 3px 6px #707070',
        borderRadius: '21px',
        width: '100%',
        padding: { xs: 4, md: 8 }
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" rowGap={2}>
        <img src={Assets.search} width="100px" height="100px" />
        <Typography variant={isSmartphoneScreen ? 'h4' : 'h1'} textAlign={'center'}>{props.message}</Typography>
      </Box>
    </Box>
  );
};
