import { format } from 'date-fns';
import { inject } from 'inversify';
import { computed } from 'mobx';
import { IGoogleMapProps } from '../../../../../domain/core/IGoogleMapProps';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { EntryModel } from '../../../../../domain/model/EntryModel';
import { transient } from '../../../../../inversify/decorator';
import { ClassificationHelper } from '../../../../../util/classification/ClassificationHelper';
import { ClassificationTranslationService } from '../../../../../util/classification/ClassificationTranslationService';
import { EntryHelper } from '../../../../../util/EntryHelper';


export interface EntryMarkerProps extends IGoogleMapProps {
  entry: EntryModel;
  opacity: number;
  onClick: (entry: EntryModel) => void;
  tooltipEnabled: boolean;
}

@transient()
export class EntryMarkerVm extends ViewModel<EntryMarkerProps> {

  constructor(
    @inject(ClassificationTranslationService) private readonly classificationTranslationService: ClassificationTranslationService,
    @inject(ClassificationHelper) private readonly classificationHelper: ClassificationHelper,
    @inject(EntryHelper) private readonly entryHelper: EntryHelper,
  ) {
    super();
  }

  @computed
  public get ownerName(): string {
    return this.props.entry.ownerName;
  }

  @computed
  public entryDate = (pattern: string): string => {
    if (!this.props.entry.userDate) return '';
    return format(this.props.entry.userDate, pattern);
  }

  @computed
  public get classificationDescription(): string | undefined {
    return this.classificationTranslationService.getFullTranslationForEntry(this.props.entry);
  }

  @computed
  public get entryHoverBackground(): string {
    return this.classificationHelper.getEntryHoverBackground(this.props.entry.entryType);
  }

  @computed
  public get entryIcon(): string {
    return this.entryHelper.getEntryIcon(this.props.entry, false);
  }
}
