import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { Assets } from '../../../assets';
import { useContainer } from '../../../domain/hooks/useContainer';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { GroupDiscountVm } from '../group-discount/GroupDiscountVm';

interface GroupSubscriptionsCounterProps {
  groupDiscountVm: GroupDiscountVm;
  disabled?: boolean;
}

export function GroupSubscriptionsCounter(props: GroupSubscriptionsCounterProps) {

  const tracking = useContainer().get(TrackingService);
  const sessionStore = useContainer().get(SessionStore);
  const [minimumAmountOfSubscriptions] = useState(1);

  useEffect(() => {
    props.groupDiscountVm.handleCounter(props.groupDiscountVm.quantity);
    tracking.track(TrackingEvent.AMOUNT_CHANGED);
  }, [props.groupDiscountVm, props.groupDiscountVm.quantity, sessionStore.userId, tracking]);

  return (
    <Grid container justifyContent="space-around" alignItems="center" sx={{ opacity: props.disabled ? 0.75 : 1 }}>
      <Grid item>
        <img
          src={Assets.counter_minus}
          width={40}
          height={40}
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (props.disabled) {
              return;
            }
            props.groupDiscountVm.handleCounter(
              props.groupDiscountVm.quantity > minimumAmountOfSubscriptions ? props.groupDiscountVm.quantity - 1 : props.groupDiscountVm.quantity
            );
          }}
        />
      </Grid>
      <Grid
        item container
        justifyContent="center"
        alignItems="center"
        width="88px"
        height="72px"
        border="2px solid #FFFFFF"
        borderRadius="8px"
        boxShadow="0px 3px 6px #0000003D"
        sx={{
          background: 'transparent linear-gradient(180deg, #E6670A 0%, #FDB95B 100%) 0% 0% no-repeat padding-box'
        }}
        textAlign="center"
      >
        <Grid item>
          <Typography variant="h1">{props.groupDiscountVm.quantity}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <img
          src={Assets.counter_plus}
          width={40}
          height={40}
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (props.disabled) {
              return;
            }
            props.groupDiscountVm.handleCounter(props.groupDiscountVm.quantity + 1);
          }}
        />
      </Grid>
    </Grid>
  );
}
