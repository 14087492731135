import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, useMediaQuery } from '@mui/material';

import { useContainer } from '../../../../../domain/hooks/useContainer';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { colors } from '../../../../../theme/options/palette/const/colors';

export interface UserColumnProps {
  user: GroupSubscriptionUserModel;
}

export function UserColumn(props: UserColumnProps) {
  const sessionStore = useContainer().get(SessionStore);
  const { t } = useTranslation();
  const isSmartphoneScreen = useMediaQuery('(max-width:750px)');

  return (
    <Grid container direction={'row'} gap={2}>
      {!isSmartphoneScreen && <Grid item flexGrow={0} alignSelf={'center'}>
        <Avatar name={props.user.fullName} round size={'40px'} src={props.user.avatar?.thumbnailUrl || ''} />
      </Grid>}
      <Grid item container direction={'column'} alignSelf={'center'} sx={{
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 0,
      }}>
        <Grid item>
          <Typography display={'inline-block'}>{props.user.fullName}</Typography>
          &nbsp;
          <Typography color={colors.orange[500]} display={'inline-block'}>
            {sessionStore.userId === props.user.id ? t('buy_group_subscription:own_user') : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ lineBreak: 'anywhere' }}>{props.user.email}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
