import React, { useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { Box, Grid, Modal, Typography, useTheme } from '@mui/material';

import Logo from '../../../assets/img/LogoIcon.svg';
import { useContainer } from '../../../domain/hooks/useContainer';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { IEventParams } from '../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { appRoutes } from '../../../router/routes';
import { colors } from '../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../toolkit/components/SubmitButton';
import { FeaturesTable } from '../../../toolkit/icons/features/FeaturesTable';

export type FeaturesModalRef = {
  open: () => void,
  close: () => void,
}

interface FeaturesModalProps extends React.PropsWithChildren<unknown> {
  event?: TrackingEvent;
  params?: IEventParams;
}

export const FeaturesModal = React.forwardRef<FeaturesModalRef, FeaturesModalProps>(function FeaturesModal(props: FeaturesModalProps, ref) {
  const tracking = useContainer().get(TrackingService);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const handleOpen = useCallback(() => {
    if (props.event) {
      tracking.track(TrackingEvent.PRO_FREE_COMPARISON_VIEW);
    }

    return setOpen(true);
  }, [props.event, tracking]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    if (location.pathname === appRoutes.pricing) {
      return handleClose();
    }

    return navigate(appRoutes.pricing);
  }, [handleClose, location, navigate]);

  return (
    <React.Fragment>
      <SubmitButton
        onClick={handleOpen}
        sx={{
          bgcolor: colors.orange[400],
          '&:hover': {
            bgcolor: colors.orange[600]
          }
        }}
      >
        {t('payment:features.pro_features_button')}
      </SubmitButton>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          position: 'absolute',
          backgroundColor: colors.paper,
          border: '1px solid #707070',
          borderRadius: '20px',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          maxWidth: '90%',
        }}>
          <Grid container alignItems="center">
            <Grid item>
              <img src={Logo} width="57px" height="57px" />
            </Grid>
            <Grid item xs>
              <Typography textAlign={'center'} variant="h6" color="#000">{t('payment:modal.title')}</Typography>
            </Grid>
            <Grid item xs={12} textAlign={'end'} pb={2} position={'relative'}>
              <SubmitButton
                onClick={handleClick}
                sx={[
                  {
                    position: 'absolute',
                    right: '7%',
                    minWidth: 200,
                    maxWidth: 500,
                    bgcolor: colors.orange[500],
                    '&:hover': {
                      bgcolor: colors.orange[700]
                    }
                  },
                  location.pathname === appRoutes.pricing || location.pathname === appRoutes.subscriptionPurchase ? { display: 'none' } : {}
                ]}
              >
                {t('payment:button.purchase')}
              </SubmitButton>
            </Grid>
            <Grid item xs={12}>
              <FeaturesTable />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
});
