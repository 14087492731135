import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { ViewModel } from '../../../../../domain/core/ViewModel';
import { AuthProxy } from '../../../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import {
  appendToppleGoalTagRegisterConversionPixel
} from '../../../../../domain/service/tracking/ToppleTrackingUtil';
import { TrackingEvent } from '../../../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { Types } from '../../../../../inversify/types';
import { appRoutes } from '../../../../../router/routes';
import { SocialPostRequestDto } from '../../../../../shared/dto';
import { LANGUAGE } from '../../../../../shared/enum';
import { OAuthType, SocialMediaRegistrationForm } from './SocialMediaRegistrationRoute';

@transient()
export class SocialMediaRegistrationRouteVm extends ViewModel {

  constructor(
    @inject(SessionStore) private readonly sessionStore: SessionStore,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  public socialMediaRegister = async (data: SocialMediaRegistrationForm): Promise<void> => {
    try {
      const requestBody: SocialPostRequestDto = {
        access_token: data.access_token,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        agbAccepted: data.privacyAccepted,
        language: this.i18n.language as LANGUAGE,
      };

      let response, registrationType: RegistrationVia;

      switch (data.type) {
        case OAuthType.Facebook:
          response = await this.sessionStore.loginViaFacebook(requestBody);
          registrationType = RegistrationVia.FACEBOOK;
          break;
        case OAuthType.Google:
          response = await this.sessionStore.loginViaGoogle(requestBody);
          registrationType = RegistrationVia.GOOGLE;
          break;
        case OAuthType.Apple:
          response = await this.sessionStore.loginViaApple(requestBody);
          registrationType = RegistrationVia.APPLE;
          break;
      }

      if (response.ok) {
        await this.tracking.track(TrackingEvent.REGISTRATION_COMPLETED, { registrationType });
        appendToppleGoalTagRegisterConversionPixel();
        this.navigate(appRoutes.map);
        return this.notification.success(this.i18n.t('auth:welcome_message'));
      }

      if (response.status === 409) {
        this.notification.error(this.i18n.t('auth:existing_email_error'), {
          autoHideDuration: 8000,
        });
        setTimeout(() => this.navigate(appRoutes.login, { state: data.email }), 2000);
        return;
      }

      this.notification.error(this.i18n.t('auth:login_error'));
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error(this.i18n.t('auth:login_error'));
    }
  }

  @computed
  public get isBusy() {
    return this.authProxy.loginAuthViaApple.isBusy || this.authProxy.loginAuthViaGoogle.isBusy || this.authProxy.loginAuthViaFacebook.isBusy;
  }
}
