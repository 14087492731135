import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useBackListener } from '../../../../../domain/hooks/useBackListener';
import { useVm } from '../../../../../domain/hooks/useVm';
import { LoadingFallback } from '../../../../../LoadingFallback';
import { appRoutes } from '../../../../../router/routes';
import { ForwardingBlockerModal } from '../../../../payment-modals/ForwardingBlockerModal';
import { AddMemberRouteVm } from './AddMemberRouteVm';
import { AddMemberScreen } from './AddMemberScreen';
import { ManageVoucherSeats } from './manage-vouchers-seats/ManageVoucherSeats';

export const AddMemberRoute = observer(function AddMemberRoute() {
  const navigate = useNavigate();
  const vm = useVm(AddMemberRouteVm);

  useEffect(() => {
    if (!vm.sessionStore.session?.isGroupOwner) {
      navigate(appRoutes.userProfile);
    }
  }, [navigate, vm.sessionStore.session?.isGroupOwner]);

  useBackListener(({ location }) => {
    if (location.pathname === appRoutes.groupMemberAdder) {
      const stepBackRoute = location.pathname.replace(/\/[^/]+$/, '');
      navigate(stepBackRoute);
    }
  });

  return <React.Fragment>
    {!vm.fetchData.isBusy
      ? (vm.unredeemedVouchers.length !== 0
        ? <ManageVoucherSeats vm={vm} />
        : <AddMemberScreen vm={vm} />)
      : <LoadingFallback />
    }
    {vm.groupSubscriptionModel?.paymentResolveUrl && vm.shouldRenderForwardingBlockerModal && <ForwardingBlockerModal paymentResolveUrl={vm.groupSubscriptionModel?.paymentResolveUrl} />}
  </React.Fragment>;

});
