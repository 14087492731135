import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

type LocationType = { pathname: string };

type CallbackFn = (args: { location: LocationType; action: string }) => void;

export const useBackListener = (callback: CallbackFn) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }: { location: LocationType; action: string }) => {
      if (action === 'POP') {
        callback({ location, action });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unlisten = (navigator as any).listen(listener);
    return unlisten;
  }, [callback, navigator]);
};
