import { makeObservable, observable } from 'mobx';

import { StripeVoucherDto } from '../../../shared/dto/stripe/stripe.voucher.dto';
import { STRIPE_VOUCHER_STATUS } from '../../../shared/enum/stripe/stripeVoucherStatus.enum';
import { UserModel } from '../UserModel';

export class StripeVoucherModel {

  @observable
  public id: string;

  @observable
  public code: string;

  @observable
  public validUntil: string;

  @observable
  public redeemer: UserModel | undefined;

  @observable
  public status: STRIPE_VOUCHER_STATUS;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: StripeVoucherDto) => {
    const voucher = new StripeVoucherModel();

    voucher.id = dto.id;
    voucher.code = dto.code;
    voucher.validUntil = dto.validUntil;
    if (dto.redeemer) {
      voucher.redeemer = UserModel.fromDto(dto.redeemer);
    }
    voucher.status = dto.status;

    return voucher;
  }
}
