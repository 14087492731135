import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { StripePriceTierModel } from '../../domain/model/stripe/StripePriceTierModel';
import { StripeProductModel } from '../../domain/model/stripe/StripeProductModel';
import { StripeProxy } from '../../domain/proxy/StripeProxy';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { STRIPE_PRICING_MODEL } from '../../shared/enum/stripe/stripePricingModel.enum';
import { currencySymbol } from '../../util/CurrencyHelper';
import { SessionStore } from '../../domain/store/SessionStore';

export abstract class GroupPricingVm extends ViewModel {

  @observable
  protected product: StripeProductModel | null;

  constructor(
    @inject(StripeProxy) protected readonly stripeProxy: StripeProxy,
    @inject(I18nService) protected readonly i18n: I18nService,
    @inject(NotificationService) protected readonly notification: NotificationService,
    @inject(SessionStore) protected readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.fetchData.run();
  }

  public fetchData = new AsyncTask(async () => {
    await Promise.all([
      this.getProducts.run(),
    ]);
  })

  @computed
  public get currency(): string {
    return currencySymbol(this.product?.price?.currency ?? 'eur');
  }

  @computed
  protected get tiers(): StripePriceTierModel[] {
    if (!this.product) return [];
    return this.product.price?.tiers;
  }

  /**
   * It should be tiers of 2 items in GRADUATED pricing model.
   * In VOLUME pricing model are more tiers where price depends on quantity.
   * quantity === 1 -> use tiers[0] which has flatAmount
   * quantity > 1 -> use tiers[1] which has unitAmount
  */
  @computed
  protected get priceForOneUser(): number {
    return this.tiers[0].flatAmount as number;
  }

  @computed
  protected get priceForEveryNextUser(): number {
    return this.tiers.find(tier => tier.upTo === null)?.unitAmount ?? this.priceForOneUser;
  }

  protected abstract get groupPricePerMemberPerYear(): number;

  protected abstract get groupTotalPrice(): number;

  @action
  private setProduct = (product: StripeProductModel) => {
    this.product = product;
  }

  protected getProducts = new AsyncTask(async (): Promise<void> => {
    try {
      const result = await this.stripeProxy.getProducts({ userId: this.sessionStore.userId });

      if (!result.ok || !result.data) {
        this.notification.error(this.i18n.t('payment:errors.products_warning'));
        return;
      }

      //  * use this for a "STRIPE_PRICING_MODEL.VOLUME"
      /* const productByLanguageAndPricingModel = result.data
        .filter(product => product.tiered)
        .find(product => product.metadata?.language === this.i18n.language
          && product.metadata.pricingModel === STRIPE_PRICING_MODEL.VOLUME
          && product.price.tiers.every(tier => tier.flatAmount === 0)
          ); */

      // * use this for a "STRIPE_PRICING_MODEL.GRADUATED"
      const productByLanguageAndPricingModel = result.data
        .filter(product => product.tiered)
        .find(product => product.metadata?.pricingModel === STRIPE_PRICING_MODEL.GRADUATED
        );

      if (productByLanguageAndPricingModel) {
        this.setProduct(productByLanguageAndPricingModel);
      }

    }
    catch (error) {
      this.notification.error(this.i18n.t('payment:errors.products_error'));
    }
  });

}
