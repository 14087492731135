export enum SUBSCRIPTION_TYPE {
  FREE = 'FREE',
  LITE = 'LITE',
  PLUS = 'PLUS',
  PRO = 'PRO',
  DEV = 'DEV',
  UNLIMITED = 'UNLIMITED',
  JAGD_UND_NATUR = 'JAGD_UND_NATUR'
}


export enum USER_ROLE_IN_GROUP {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
  NON_MEMBER = 'NON_MEMBER'
}

export enum SUBSCRIPTION_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
  PENDING_DELETE = 'PENDING_DELETE',
  DELETED = 'DELETED'
}

