import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';

import { Assets } from '../../../assets';
import { useContainer } from '../../../domain/hooks/useContainer';
import { useVm } from '../../../domain/hooks/useVm';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { env } from '../../../env';
import { LoadingFallback } from '../../../LoadingFallback';
import { appRoutes } from '../../../router/routes';
import { colors } from '../../../theme/options/palette/const/colors';
import { palette } from '../../../theme/options/palette/palette';
import { Background } from '../../../toolkit/components/Background';
import { IconAndTitle } from '../../../toolkit/components/IconAndTitle';
import {
  discountBulletList, groupDiscountBulletList, IGroupDiscountBulletList
} from '../../../toolkit/constants/GroupDiscountList';
import { GroupSubscriptionsCounter } from '../components/GroupSubscriptionsCounter';
import { GroupDiscountVm } from './GroupDiscountVm';

export const GroupDiscount = observer(function GroupDiscount() {
  const { t } = useTranslation();

  const vm = useVm(GroupDiscountVm);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:1297px)');
  const isExtraSmallScreen = useMediaQuery('(max-width:738px)');

  const tracking = useContainer().get(TrackingService);
  const sessionStore = useContainer().get(SessionStore);

  useEffect(() => {
    if (sessionStore.currentUser?.isPartOfGroupSubscription) {
      navigate(appRoutes.userProfile);
    }

    tracking.track(TrackingEvent.GROUPSUBSCRIPTIONPRICING_PAGE_VIEW);

    if (vm.webWidget) {
      vm.webWidget.hidden = false;
    }

    return () => {
      if (vm.webWidget) {
        vm.webWidget.hidden = true;
      }
    };
  }, [navigate, sessionStore.currentUser?.isPartOfGroupSubscription, sessionStore.userId, tracking, vm.webWidget]);

  if (vm.fetchData.isBusy) {
    return <LoadingFallback />;
  }

  return (
    <Background paddingY={{ xs: 7 }}>

      <Grid container direction="column">
        <Grid item>
          <IconAndTitle title="group_discount:title" subtitle="group_discount:subtitle" />
        </Grid>
      </Grid>

      <Grid container item direction="row" justifyContent={'center'}
        paddingY={2}
        rowSpacing={2}
        width={{ xs: '100%', xl: '90%' }}
      >
        {/* ICON BOX */}
        <Grid item container direction="column" alignItems="center" justifyContent="center"
          marginY={2}
          width={{ xs: '100%', xl: '90%' }}
        >
          <Grid
            item container
            position="relative"
            border={1}
            borderColor={colors.orange[400]}
            borderRadius={'20px'}
            bgcolor={palette.background?.paper}
          >
            <Grid item container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ position: 'absolute', top: '-28px' }}
            >
              <Grid item>
                <img src={Assets.shield} height="50px" width="50px" />
              </Grid>
            </Grid>
            <Grid item container
              justifyContent="center"
              textAlign="center"
              padding={2}
              color={colors.green[800]}
            >
              <Grid item xs={12}>
                <Typography fontSize={{ xs: 28, md: 32 }} fontWeight="bold">
                  {t('group_discount:body.box_one.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('group_discount:body.box_one.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* GROUP DISCOUNT BOX */}
        <Grid container justifyContent="center">
          <Grid
            item container
            justifyContent={{ xs: 'space-evenly' }}
            columnGap={2}
            rowGap={2}
            alignItems="flex-start"
            sx={{ borderRadius: '20px' }}
          >
            {/* FIRST BOX */}
            <Grid item container direction="column" alignItems="center"
              // width={{ xs: 300, md: 350, xl: 400 }}
              width={isSmallScreen ? (isExtraSmallScreen ? 350 : 320) : 360}
              minHeight={504}
              border={1}
              borderColor={colors.orange[400]}
              borderRadius={'20px'}
              bgcolor={palette.background?.paper}
              px={1}
              sx={{ opacity: 0.75 }}
            >
              <Grid item mt={2}>
                <img src={Assets.discount_icon} width="98px" height="84px" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h4" color={palette.text?.hunter_green}>
                  {t('group_discount:body.box_two.item_one.title')}
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="left">
                <ol style={{ fontSize: '18px', color: '#000', textAlign: 'center' }}>
                  {discountBulletList.map((value: IGroupDiscountBulletList) => {
                    return (
                      <li key={`discount-${value.description}`} style={{ padding: '8px' }}>
                        <span>{t(`${value.description}`)}</span>
                      </li>
                    );
                  })}
                </ol>
              </Grid>
            </Grid>

            {/* SECOND BOX */}
            <Grid item container direction="column" alignItems="center"
              // width={{ xs: 300, md: 350, xl: 400 }}
              width={isSmallScreen ? (isExtraSmallScreen ? 350 : 320) : 350}
              rowGap={3}
            >
              <Grid item container
                minHeight={504}
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                color="#fff"
                paddingY={2}
                rowGap={2}
                px={1}
                border={1}
                borderColor={colors.orange[400]}
                borderRadius={'20px'}
                bgcolor={palette.background?.paper}
                sx={{
                  background: colors.green.hunterGreen,
                  boxShadow: '3px 6px 6px #0000003D',
                }}>
                {/* DESCRIPTION */}
                <Grid item textAlign="center">
                  <Typography variant="h4">{t('group_discount:body.box_two.item_two.title')}</Typography>
                  <Typography fontSize={14} fontWeight="normal">{t('group_discount:body.box_two.item_two.subtitle')}</Typography>
                  <Typography fontSize={12} fontWeight="normal">{`${t('group_discount:body.box_two.item_two.minimum')}: ${vm.minimumAmountOfSubscriptions}`}</Typography>
                </Grid>

                {/* COUNTER */}
                <Grid item>
                  <GroupSubscriptionsCounter groupDiscountVm={vm} />
                </Grid>

                <Grid item container direction={'column'} alignItems={'center'} rowGap={2} marginTop={3}>
                  {/* PRICE PER USER */}
                  <Box minWidth={'50%'} maxWidth={'70%'} display="flex" flexDirection="column" alignItems="center" position="relative">
                    <img src={Assets.shield} height="40px" width="40px" style={{ position: 'absolute', top: '-24px' }} />
                    <Grid item container
                      height={87}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      paddingY={1}
                      px={4}
                      sx={{ background: '#fff', borderRadius: '20px', color: '#000' }}
                    >
                      <Grid item marginTop={1}>
                        <Typography variant="h1">
                          {`${vm.groupPricePerMemberPerYear.toFixed(2)}${vm.currency}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontSize="10px" fontWeight="light">{t('group_discount:body.box_two.item_two.text_1')}</Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* SAVINGS */}
                  <Box minWidth={'50%'} maxWidth={'80%'}>
                    <Grid item container
                      minHeight={87}
                      maxHeight={100}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      px={4}
                      sx={{ background: '#B2DED4', borderRadius: '20px', color: '#000' }}
                    >
                      <Grid item color="#FE002F" textAlign="center">
                        <Typography fontSize="10px" fontWeight="light">
                          {`${t('group_discount:body.box_two.item_two.text_2')} ${vm.savings.toFixed(2)}${vm.currency}`}
                        </Typography>
                        <Typography fontSize="16px" fontWeight="bold" sx={{ textDecorationLine: 'line-through' }}>
                          {`${vm.regularPrice.toFixed(2)}${vm.currency}`}
                        </Typography>
                      </Grid>
                      <Grid item color="#000" textAlign="center" marginBottom="3px">
                        <Typography fontSize="18px" fontWeight="bold">
                          {`${vm.groupTotalPrice.toFixed(2)}${vm.currency}`}
                        </Typography>
                        <Typography fontSize="10px" fontWeight="light">{t('group_discount:body.box_two.item_two.text_3')}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item container justifyContent={'center'}>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: colors.orange[400],
                        paddingX: 4,
                        boxShadow: '3px 6px 6px #0000003D',
                        borderRadius: '40px',
                        border: '1px solid #FFA51C',
                        width: '100%',
                        minHeight: '53px',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: colors.orange[500],
                        }
                      }}
                      onClick={() => navigate(appRoutes.buyGroupSubscription)}
                    >
                      <Typography variant="h4">{t('group_discount:refund.modal_button')}</Typography>
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            {/* THIRD BOX */}
            <Grid item container direction="column" alignItems="center"
              // width={{ xs: 300, md: 350, xl: 400 }}
              width={isSmallScreen ? (isExtraSmallScreen ? 350 : 320) : 350}
              minHeight={504}
              border={1}
              borderColor={colors.orange[400]}
              borderRadius={'20px'}
              bgcolor={palette.background?.paper}
              px={1}
              sx={{ opacity: 0.75 }}
            >
              <Grid item mt={2}>
                <img src={Assets.group_discount_icon} width="98px" height="84px" />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h4" color={palette.text?.hunter_green}>
                  {t('group_discount:body.box_two.item_three.title')}
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="left">
                <ol style={{ fontSize: '18px', color: '#000', textAlign: 'center' }}>
                  {groupDiscountBulletList.map((value: IGroupDiscountBulletList) => {
                    return (
                      <li key={`discount-${value.description}`} style={{ padding: '8px' }}>
                        <span>{t(`${value.description}`)}</span>
                      </li>
                    );
                  })}
                </ol>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Zendesk defer zendeskKey={env.zendesk.key} onLoaded={() => ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { search: 'group-discount' })} />

    </Background >
  );
});
