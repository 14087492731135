import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Link, Typography } from '@mui/material';

import { colors } from '../../../../../../theme/options/palette/const/colors';
import { IconAndTitle } from '../../../../../../toolkit/components/IconAndTitle';
import { SubmitButton } from '../../../../../../toolkit/components/SubmitButton';
import { NoDataTable } from '../../components/NoDataTable';
import { AddMemberTable } from '../add-member-table/AddMemberTable';
import { AddMemberRouteVm } from '../AddMemberRouteVm';

interface ManageVoucherSeatsProps {
  vm: AddMemberRouteVm;
}

export const ManageVoucherSeats = observer(function ManageVoucherSeats({ vm }: ManageVoucherSeatsProps) {

  const { t } = useTranslation();

  return (
    <Box
      paddingY={{ xs: 7 }}
      paddingLeft={{ xs: '3%', md: '8%' }}
      paddingRight={'3%'}
      sx={{
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      <Box display="flex" flexDirection="column">

        <IconAndTitle title={t('manage_group:title')} subtitle={t('manage_group:subtitle')} />

        <Box display={'flex'} flexDirection={'column'} rowGap={3} maxWidth={{ xs: '100%', lg: '90%' }}>
          {/* HEADER */}
          <Grid container direction={'row'} rowSpacing={0.5}>
            <Grid item xs={12}>
              <Typography variant='h6' fontWeight={'bolder'}>
                {t('manage_group:add_members.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' fontWeight={'normal'}>
                {t('manage_group:manage_vouchers_seats.body_1')}
                &nbsp;
                <Link variant={'h6'}
                  fontWeight={'normal'}
                  target={'_blank'}
                  href={t('links:add_people')}
                  sx={{ textDecoration: 'underline' }}
                >
                  {t('manage_group:manage_vouchers_seats.body_2')}
                </Link>
                {t('manage_group:manage_vouchers_seats.body_3')}
              </Typography>
            </Grid>
          </Grid>
          {/* SUMMARY */}
          <Grid container direction={'row'} rowSpacing={0.5}>
            <Grid item xs={12}>
              <Typography variant='subtitle1' fontWeight={'normal'}>
                {vm.selectedUsers.size}{t('manage_group:manage_vouchers_seats.subscription_summary_info_1')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' fontWeight={'normal'}>
                {vm.unredeemedVouchers.length}&nbsp;{t('manage_group:manage_vouchers_seats.subscription_summary_info_2')}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant='subtitle1' fontWeight={'bolder'}>{t('manage_group:manage_vouchers_seats.subscription_summary_info_3', { count: vm.selectedUsers.size })}</Typography>
              </Grid>
              <Grid item>
                <SubmitButton
                  onClick={vm.updateGroupSubscription.run}
                  variant='contained'
                  disabled={!vm.selectedUsers.size}
                  submitting={vm.updateGroupSubscription.isBusy}
                  sx={{
                    borderRadius: 0,
                    fontSize: 15,
                    px: 2,
                    minWidth: 150,
                    height: 50,
                    boxShadow: '3px 6px 6px #0000003D',
                    bgcolor: colors.orange[400],
                    '&:hover': {
                      bgcolor: colors.orange[600]
                    }
                  }}
                >
                  {t('manage_group:manage_vouchers_seats.button')}
                </SubmitButton>
              </Grid>
            </Grid>
          </Grid>
          {/* TABLE */}
          <Grid container direction={'row'}>
            {vm.relatedMembers.length !== 0
              ? <AddMemberTable
                relatedMembers={vm.relatedMembers}
                handleCheckboxChange={vm.handleCheckboxChange}
                voucherSeatsLeft={vm.unredeemedVouchers.length}
                selectedUsers={vm.selectedUsers}
              />
              : <NoDataTable message={t('manage_group:add_members.table.no_related_members')} />
            }
          </Grid>
        </Box>
      </Box>
    </Box>
  );
});
