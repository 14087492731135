
import { redesignColors } from './const/redesignColors';

export const lightThemePalette = {
  background: {
    surface: redesignColors.system.white,
    surface_80: `${redesignColors.system.white}CC`,  // * adding 80% opacity
    cta_default: redesignColors.cta[300],
    cta_active: redesignColors.cta[200],
    container: redesignColors.grey[50],
    container_2: redesignColors.grey[100],
    success_background: redesignColors.brand[50],
    error_default: redesignColors.error[800],
    error_background: redesignColors.error[100],
  },
  text: {
    primary: redesignColors.grey[900],
    secondary: redesignColors.grey[500],
    tertiary: redesignColors.grey[300],
  },
  brand: {
    brand_color: redesignColors.brand[800],
    dark: redesignColors.brand[900],
    light: redesignColors.brand[700],
    selection: redesignColors.brand[800],
  },
  icons: {
    primary: redesignColors.grey[900],
    secondary: redesignColors.grey[500],
    tertiary: redesignColors.grey[300],
  },
  divider: redesignColors.grey[50],
  borderDividers: {
    divider: redesignColors.grey[50],
    border: redesignColors.grey[50],
    lighter_darker: redesignColors.grey[100],
  },
  buttons: {
    primary: redesignColors.brand[800],
    secondary: redesignColors.grey[50],
    map_button: redesignColors.system.white,
    navigation_button_active: redesignColors.brand[600]
  },
  misc: {
    selected: redesignColors.misc.border_selected,
    unselected: redesignColors.grey[100],
    drawing: redesignColors.misc.border_drawing,
  },
  system: {
    white: redesignColors.system.white,
    black: redesignColors.system.black,
  }
};
