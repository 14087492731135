import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Link, Tooltip, Typography } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import { LoadingFallback } from '../../../../LoadingFallback';
import { appRoutes } from '../../../../router/routes';
import { colors } from '../../../../theme/options/palette/const/colors';
import { IconAndTitle } from '../../../../toolkit/components/IconAndTitle';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { NoDataTable } from './components/NoDataTable';
import { ManageGroupTable } from './manage-group-table/ManageGroupTable';
import { ManageGroupRouteVm } from './ManageGroupRouteVm';

export const ManageGroupRoute = observer(function ManageGroupRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const vm = useVm(ManageGroupRouteVm);

  useEffect(() => {
    if (!vm.sessionStore.session?.isGroupOwner) {
      navigate(appRoutes.userProfile);
    }
  }, [navigate, vm.sessionStore.session?.isGroupOwner]);

  const renderSubscriptionInfo = useMemo(() => {
    return (
      <React.Fragment>
        {vm.isIncompleteOrPastDue
          ? <Typography variant='h6' fontWeight={'bolder'}>
            {t('manage_group:header.title_update_payment')}
          </Typography>
          : <Typography variant='h6' fontWeight={'bolder'}>
            {t('manage_group:header.title', {
              count: vm.groupSubscriptionInfo.quantity,
              status: vm.groupSubscriptionStatusTranslation,
              action: vm.groupSubscriptionActionTranslation,
              date: vm.expiresAt
            })}
          </Typography>
        }
      </React.Fragment>
    );
  }, [vm.isIncompleteOrPastDue, vm.groupSubscriptionInfo.quantity, vm.groupSubscriptionStatusTranslation, vm.groupSubscriptionActionTranslation, vm.expiresAt, t]);

  const renderAddMemberButton = useMemo(() => (
    <Grid item>
      <Button
        disabled={Boolean(vm.groupSubscriptionInfo.canceledAt)}
        startIcon={<AddIcon />}
        onClick={() => navigate(appRoutes.groupMemberAdder)}
        variant='contained'
        sx={{
          bgcolor: colors.yellow[700],
          fontSize: 15,
          px: 2,
          minWidth: 150,
          height: 50,
          boxShadow: '3px 6px 6px #0000003D',
          '&:hover': {
            bgcolor: colors.yellow[800],
          }
        }}
      >{t('manage_group:button.add_members')}</Button>
    </Grid>), [navigate, t, vm.groupSubscriptionInfo.canceledAt]);

  const renderCanceledSubscriptionTooltip = useMemo(() => {
    return (
      <Tooltip arrow placement='top'
        title={<Box>
          <Grid container direction="column" justifyContent="center" sx={{ borderRadius: 10 }}>
            <Grid item padding={0.5}
              textAlign="center"
              sx={{
                color: colors.paper,
                borderRadius: '4px',
                border: '1px solid black',
              }}
            >
              <Typography fontSize={14} fontWeight="regular" color={'#000'}>
                {t('manage_group:members.canceled_sub_tooltip')}
              </Typography>
            </Grid>
          </Grid>
        </Box>}>
        {renderAddMemberButton}
      </Tooltip>
    );
  }, [renderAddMemberButton, t]);

  if (vm.dataLoadingInProgress) {
    return <LoadingFallback />;
  }

  return <Box
    paddingY={{ xs: 7 }}
    paddingLeft={{ xs: '3%', md: '8%' }}
    paddingRight={'3%'}
    sx={{
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: '100vh',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
    }}
  >
    <Box display="flex" flexDirection="column">

      <IconAndTitle title={t('manage_group:title')} subtitle={t('manage_group:subtitle')} />

      <Box display={'flex'} flexDirection={'column'} rowGap={5} maxWidth={{ xs: '100%', lg: '90%' }}>

        {/* HEADER */}
        <Grid container direction={'row'} rowSpacing={0.5}>
          <Grid item xs={12}>
            {renderSubscriptionInfo}
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={'normal'}>{t('manage_group:header.body')}</Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <SubmitButton
              onClick={vm.createPortalSession}
              sx={{
                fontSize: 15,
                minWidth: 200,
                height: 50,
                boxShadow: '3px 6px 6px #0000003D',
              }}
            >{t('profile:button.manage_subscription')}</SubmitButton>
          </Grid>
        </Grid>

        {/* ADD MEMBERS */}
        <Grid container direction={'row'} rowSpacing={0.5}>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={'bolder'}>{t('manage_group:members.title')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={'normal'}>
              {t('manage_group:members.body_1')}
              &nbsp;
              <Link
                fontWeight={'normal'}
                onClickCapture={vm.handleClickOnALink}
                target={'_blank'}
                href={t('links:learn_how_we_bill')}
                sx={{ textDecoration: 'underline' }}
              >
                {t('manage_group:members.body_2')}
              </Link>
              {t('manage_group:members.body_3')}
            </Typography>
          </Grid>


          <Grid item container xs={12} mt={1} columnGap={4} alignItems={'center'}>
            {vm.groupSubscriptionInfo.canceledAt ? renderCanceledSubscriptionTooltip : renderAddMemberButton}
          </Grid>
        </Grid>

        {/* TABLE */}
        <Grid container direction={'row'}>
          {vm.allParticipantsOfGroup.length !== 0 && vm.groupSubscriptionInfo.owner
            ? <ManageGroupTable
              groupParticipants={vm.allParticipantsOfGroup}
              groupOwner={vm.groupSubscriptionInfo.owner}
              getSubscriptionInfo={vm.getSubscriptionInfo.run}
              coupon={vm.groupSubscriptionInfo.coupon} />
            : <NoDataTable message={t('manage_group:table_no_data')} />
          }
        </Grid>

      </Box>

    </Box>
  </Box>;

});
