import { useCallback, useState } from 'react';

import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';

import { colors } from '../../../../theme/options/palette/const/colors';
import { useContainer } from '../../../../domain/hooks/useContainer';
import { SessionStore } from '../../../../domain/store/SessionStore';

/**
 * ONLY FOR DEVELOPING AND TESTING PURPOSE!
 * ProBlockerModal is blocking free user to load and create any data in the app.
 * So this will be a switch in DEV mode where free user can turn off pro blocker just for testing purposes.
 */
export const FreeOrProSwitch = function FreeOrProSwitch() {

  const session = useContainer().get(SessionStore);
  const [blocker, showBlocker] = useState(localStorage.getItem(`FREE_OR_PRO-${session.userId}`) ? true : false);

  const toggleBlocker = useCallback(() => {
    localStorage.setItem(`FREE_OR_PRO-${session.userId}`, !blocker ? 'true' : '');
    showBlocker(!blocker);
  }, [blocker, session.userId]);

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      <FormGroup>
        <FormControlLabel
          labelPlacement='start'
          control={
            <Switch
              sx={{
                color: colors.paper,
              }}
            />}
          checked={blocker}
          onChange={toggleBlocker}
          label='Enable PRO stuff' />
      </FormGroup>
    </Box>
  );
};
