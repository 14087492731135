import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box, CircularProgress, DialogContent, DialogContentText, Grid, IconButton, Link, Paper, Popover,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery
} from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import {
  SubscriptionReceiptResponseModel
} from '../../../../../domain/model/SubscriptionReceiptResponseModel';
import { USER_ROLE_IN_GROUP } from '../../../../../shared/enum';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { DeleteDialog, DeleteDialogRef } from '../../../../../toolkit/components/DeleteDialog';
import { AreasColumn } from '../components/AreasColumn';
import { UserColumn } from '../components/UserColumn';
import { ManageGroupTableVm } from './ManageGroupTableVm';

export interface IGroupTableProps {
  groupParticipants: GroupSubscriptionUserModel[];
  groupOwner: GroupSubscriptionUserModel;
  getSubscriptionInfo: () => void;
  coupon?: string | null | undefined;
}

export const ManageGroupTable = observer(function ManageGroupTable(props: IGroupTableProps) {

  const { t } = useTranslation();
  const vm = useVm(ManageGroupTableVm);

  const removeMemberDialogRef: React.RefObject<DeleteDialogRef> = React.createRef();

  const isSmartphoneScreen = useMediaQuery('(max-width:750px)');

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>, groupParticipant: GroupSubscriptionUserModel) => {
    vm.setSelectedUserForRemoval(groupParticipant);
    vm.setAnchorEl(event.currentTarget);
    vm.setOpen(!vm.open);
  }, [vm]);

  const handlePopoverClose = useCallback(() => {
    vm.setOpen(false);
    vm.setAnchorEl(null);
  }, [vm]);

  const handleDeleteConfirm = useCallback(async () => {
    handlePopoverClose();
    await vm.removeUserFromGroup.run(props.coupon);

    if (!vm.removeUserFromGroup.isBusy) {
      return props.getSubscriptionInfo();
    }
  }, [handlePopoverClose, props, vm.removeUserFromGroup]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const doubleSubscriptionsTooltip = (duplicateSubscription: SubscriptionReceiptResponseModel) => {
    const platform = duplicateSubscription.platform?.toLocaleLowerCase();

    return (
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} maxWidth={'200px'} p={1} border={'0.5px solid black'}>
        <Typography textAlign={'center'} variant='caption' fontWeight={'bold'} color={colors.error}>{t('manage_group:double_subscription.title')}</Typography>
        <Typography textAlign={'center'} variant='caption'>
          {t('manage_group:double_subscription.body', { paymentPlatform: t(`profile:payment_platform.${platform}`) })}
          &nbsp;
          <Link variant={'caption'}
            fontWeight={'normal'}
            target={'_blank'}
            href={t('links:help_cancel')}
            sx={{ textDecoration: 'underline' }}
          >
            {t('manage_group:double_subscription.cancel')}
          </Link>
        </Typography>
      </Box>
    );
  };

  return (
    <TableContainer component={Paper} sx={{
      maxHeight: 600,
    }}>
      <Table stickyHeader sx={{
        // minWidth: 650,
        minHeight: 200
      }}>
        <TableHead>
          <TableRow sx={{
            '& th': {
              fontWeight: 'bold',
            }
          }}>

            {/* EMPTY */}
            {!isSmartphoneScreen && <TableCell variant='head' sx={{
              width: '3%',
              textAlign: { xs: 'left', lg: 'center' }
            }} />}

            {/* USER */}
            <TableCell variant='head' sx={{
              width: isSmartphoneScreen ? '60%' : '43%',
              paddingLeft: { xs: 1, md: 2 },
              paddingRight: { xs: 2, },
              whiteSpace: 'nowrap'
            }}>{t('manage_group:table_head.column_1')}</TableCell>

            {/* AREAS */}
            {!isSmartphoneScreen && <TableCell variant='head' sx={{
              px: { xs: 3, md: 1 },
              width: '35%'
            }}>{t('manage_group:add_members.table_head.column_3')}</TableCell>}

            {/* ACCESS LEVEL */}
            <TableCell variant='head' sx={{
              width: { xs: '30%', md: '15%' },
              paddingRight: 1,
            }}>{t('manage_group:table_head.column_3')}</TableCell>

            {/* ICON */}
            {/* <TableCell variant='head' sx={{ flexGrow: 1 }} /> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {vm.removeUserFromGroup.isBusy
            ? <TableRow>
              <TableCell colSpan={5} align="center" sx={{ p: 2 }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
            : <React.Fragment>
              {props.groupParticipants.map((groupParticipant) => (
                <TableRow
                  key={groupParticipant.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {/* EMPTY */}
                  {!isSmartphoneScreen && <TableCell component='td' sx={{
                    width: '3%',
                    bgcolor: 'transparent',
                    textAlign: { xs: 'left', lg: 'center' }
                  }} />}

                  {/* USER */}
                  <TableCell component="td" scope="row" sx={{
                    width: isSmartphoneScreen ? '60%' : '43%',
                    paddingLeft: { xs: 1, md: 2 },
                    paddingRight: { xs: 2, },
                    whiteSpace: 'wrap'
                  }}>
                    <UserColumn user={groupParticipant} />
                  </TableCell>

                  {/* AREAS */}
                  {!isSmartphoneScreen && <TableCell sx={{
                    px: { xs: 3, md: 1 },
                    width: '35%'
                  }}>
                    <AreasColumn participant={groupParticipant} />
                  </TableCell>}

                  {/* ACCESS LEVEL */}
                  <TableCell sx={{
                    width: { xs: '30%', md: '15%' },
                    paddingRight: 1,
                  }}>
                    <Typography sx={{ display: 'inline-block' }}>{groupParticipant.userRoleInGroup === USER_ROLE_IN_GROUP.OWNER ? t('manage_group:access_level.owner') : t('manage_group:access_level.member')}
                      {(groupParticipant.id !== props.groupOwner.id && props.groupParticipants.length > 2) && <IconButton
                        disableRipple
                        edge="start"
                        onClick={(event) => handlePopoverOpen(event, groupParticipant)}
                        size="small">
                        <ExpandMoreIcon sx={{ width: '0.8em' }} />
                      </IconButton>}
                    </Typography>
                    <Popover
                      open={vm.open}
                      anchorEl={vm.anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Grid container direction={'column'} sx={{ border: '1px solid black' }} px={2} py={1} rowGap={1} component={Paper}>
                        <Grid item>
                          <Typography variant='subtitle1'>{t('manage_group:remove.header')}</Typography>
                          <Typography variant='caption'>{t('manage_group:remove.body')}</Typography>
                        </Grid>
                        <Grid item>
                          <Box onClick={() => removeMemberDialogRef.current?.handleOpenDeleteDialog()}>
                            <Typography variant='subtitle2' color={colors.error} sx={{ cursor: 'pointer' }}>{t('manage_group:remove.button')}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Popover>
                  </TableCell>
                  <DeleteDialog
                    ref={removeMemberDialogRef}
                    locizeNamespace="manage_group"
                    onDelete={handleDeleteConfirm}
                  >
                    <DialogContent>
                      <DialogContentText variant="subtitle1" display={'inline-block'}>
                        {t('manage_group:delete.description_1')}
                      </DialogContentText>
                      <DialogContentText variant="subtitle1" display={'inline-block'} fontWeight={'bold'}>
                        &nbsp;{vm.selectedUserForRemoval?.email}&nbsp;
                      </DialogContentText>
                      <DialogContentText variant="subtitle1" display={'inline-block'}>
                        {t('manage_group:delete.description_2')}
                      </DialogContentText>
                      <DialogContentText variant="caption" display={'inline-block'} color={colors.error}>
                        {t('manage_group:delete.description_3')}
                      </DialogContentText>
                    </DialogContent>
                  </DeleteDialog>
                  {/* // ? This is hidden in feature/group-subs-v3 release until duplicateSubscription is not fixed on BE side */}
                  {/* <TableCell sx={{ flexGrow: 1 }} >
                    {groupParticipant.duplicateSubscription && <Tooltip title={doubleSubscriptionsTooltip(groupParticipant.duplicateSubscription)}>
                  <Icon component={ErrorIcon} htmlColor={colors.error} />
                </Tooltip>}
                  </TableCell> */}
                </TableRow>
              ))}
            </React.Fragment>
          }
        </TableBody>
      </Table >
    </TableContainer >
  );
});
