import { inject } from 'inversify';
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { StripeProductModel } from '../../domain/model/stripe/StripeProductModel';
import {
  SubscriptionReceiptResponseModel
} from '../../domain/model/SubscriptionReceiptResponseModel';
import { RevenueCatProxy } from '../../domain/proxy/RevenueCatProxy';
import { StripeProxy } from '../../domain/proxy/StripeProxy';
import { UserProxy } from '../../domain/proxy/UserProxy';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { env } from '../../env';
import { transient } from '../../inversify/decorator';
import { PAYMENT_PLATFORM } from '../../shared/enum/paymentPlatform.enum';
import { PurchasesOffering, PurchasesPackage } from '../../typings/revenue-cat';

export enum RECEIPT_PRODUCT_IDS {
  GROUP_MEMBER = 'rc_promo_PRO_yearly',
}
@transient()
export class UserProfileRouteVm extends ViewModel {

  @observable
  public product: StripeProductModel | null = null;

  @observable
  public groupProduct: StripeProductModel | null = null;

  @observable
  public subscriptionReceipts: SubscriptionReceiptResponseModel[] = [];

  constructor(
    @inject(RevenueCatProxy) private readonly revenueCatProxy: RevenueCatProxy,
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(UserProxy) private readonly userProxy: UserProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(TrackingService) public readonly tracking: TrackingService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    this.getOfferings.run().then((productsIds: string[] | undefined) => {
      if (!productsIds || !productsIds.length) return;

      this.getProducts.run(productsIds);
    });

    this.getSubscriptionReceipts.run();
  }

  @computed
  public get isSubscribedViaStripe(): boolean {
    return !!this.receipts.find((receipt: SubscriptionReceiptResponseModel) =>
      receipt.platform === PAYMENT_PLATFORM.STRIPE && !receipt.unsubscribeDetectedAt
    );
  }

  @computed
  public get receipts(): SubscriptionReceiptResponseModel[] {
    return this.subscriptionReceipts.slice().sort(a => a.active ? -1 : 1);
  }

  @computed
  public get isGroupOwner(): boolean {
    if (this.sessionStore.currentUser?.isPartOfGroupSubscription && this.sessionStore.session?.groupSubscriptionOwner) {
      return !!(this.sessionStore.session.user.id === this.sessionStore.session.groupSubscriptionOwner?.id);
    }

    return !!this.receipts.find(
      (receipt: SubscriptionReceiptResponseModel) => receipt.platform === PAYMENT_PLATFORM.STRIPE && this.sessionStore.session?.isGroupOwner
    );
  }

  @computed
  public get isGroupMember(): boolean {
    /**  Double check for group member -> user must be part of Group subscription, and in that case he needs to have info who is the group subscription owner  */
    if (this.sessionStore.currentUser?.isPartOfGroupSubscription && this.sessionStore.session?.groupSubscriptionOwner) {
      return this.sessionStore.currentUser?.isPartOfGroupSubscription;
    }

    /** When user becomes a group MEMBER, he get's platform: "PROMOTIONAL" and productId will be: "rc_promo_PRO_yearly". */
    return !!this.receipts.find(
      (receipt: SubscriptionReceiptResponseModel) =>
        receipt.platform === PAYMENT_PLATFORM.PROMOTIONAL
        && !receipt.unsubscribeDetectedAt
        && receipt.productId === RECEIPT_PRODUCT_IDS.GROUP_MEMBER);
  }

  @computed
  public get atMostOneRunningSubscription(): boolean {
    return this.receipts.filter(receipt => !receipt.unsubscribeDetectedAt).length <= 1;
  }

  @computed
  public get enableManageStripeSubscription(): boolean {
    return !!this.receipts.find(
      receipt =>
        !receipt.unsubscribeDetectedAt
        && receipt.platform === PAYMENT_PLATFORM.STRIPE
        && receipt.productId !== this.groupProduct?.id
    ) || this.sessionStore.isPlusUser;
  }

  @computed
  public get isProWithoutGroupSubscription(): boolean {
    return !this.isGroupMember && !this.isGroupOwner;
  }

  @computed
  public get isProAndGroupSubscriptionOwner(): boolean {
    return this.isGroupOwner;
  }

  @computed
  public get isProAndGroupSubscriptionMember(): boolean {
    return !this.isGroupOwner && this.isGroupMember;
  }

  public getOfferings = new AsyncTask(async (): Promise<string[] | undefined> => {
    try {
      const result = await this.revenueCatProxy.getOfferings();

      if (!result.ok || !result.data) {
        this.notification.error(this.i18n.t('payment:errors.offerings_warning'));
        return;
      }

      if (result.ok && result.data) {
        const { offerings } = result.data;
        const defaultOffering = offerings.find(
          (offering: PurchasesOffering) => offering.identifier === env.revenueCat.offerings
        ) || null;

        const productIds: string[] = defaultOffering?.packages.map((purchasePackage: PurchasesPackage) => purchasePackage.platform_product_identifier) || [];

        return productIds;
      }
    }
    catch (error) {
      this.notification.error(this.i18n.t('payment:errors.offerings_error'));
    }
  })

  public getProducts = new AsyncTask(async (productsIds: string[]): Promise<void> => {
    try {
      const allProducts = await this.stripeProxy.getProducts({ userId: this.sessionStore.userId, productsIds });

      if (!allProducts.ok || !allProducts.data) {
        this.notification.error(this.i18n.t('payment:errors.products_warning'));
        return;
      }

      return runInAction(() => {
        this.groupProduct = allProducts.data.find((product) => product.tiered) ?? null;
      });
    }
    catch (error) {
      this.notification.error(this.i18n.t('payment:errors.products_error'));
    }
  })

  public getSubscriptionReceipts = new AsyncTask(async (): Promise<void> => {
    try {
      const result = await this.userProxy.getUserSubscriptionReceipts();

      if (result.ok) {
        return runInAction(() => {
          this.subscriptionReceipts = result.data.receipts;
        });
      }

      return this.notification.error(this.i18n.t('profile:errors.subscription_receipts_warning'));
    }
    catch (error) {
      this.notification.error(this.i18n.t('profile:errors.subscription_receipts_error'));
    }
  });

}
