import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

interface SummaryTableProps {
  oldQuantity: number;
  newQuantity: number;
  oldTotal: string;
  newTotal: string;
  taxRate: string;
}

export function SummaryTable(props: SummaryTableProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/* Table Header */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={2}
        px={1}
      >
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{t('manage_group:add_members.modal.table_column_1')}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{t('manage_group:add_members.modal.table_column_2')}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{t('manage_group:add_members.modal.table_column_3')}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{t('manage_group:add_members.modal.table_column_4')}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{t('manage_group:add_members.modal.table_column_5')}</Typography>
        </Box>
      </Box>
      {/* Separator */}
      <Divider />
      {/* Table Row */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={2}
        px={1}
      >
        <Box flex={1} textAlign="center">
          <Typography variant="body1">{props.oldQuantity} {t('manage_group:add_members.modal.table_data_1')}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{props.newQuantity} {t('manage_group:add_members.modal.table_data_1')}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1">{props.oldTotal}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1" fontWeight={'bold'}>{props.newTotal}</Typography>
        </Box>
        <Box flex={1} textAlign="center">
          <Typography variant="body1">19% {t('manage_group:add_members.modal.table_data_3')}</Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
