import { Assets } from '../../assets';
import MapIcon from '../../assets/img/light_map.svg';
import UserProfile from '../../assets/img/user_profile_icon.svg';
import UserProfilePro from '../../assets/img/user_profile_pro_icon.svg';
import { appRoutes } from '../../router/routes';
import { USER_TYPE } from '../enums/userTypeEnum';

export type SidebarNavigationItem = {
  to: string;
  value: string;
  icon: string;
  visibleFor: USER_TYPE[];
};

export const sidebarNavigationList: SidebarNavigationItem[] = [
  {
    to: appRoutes.map,
    value: 'common:sidebar_mobile_item_4',
    icon: MapIcon,
    visibleFor: [USER_TYPE.FREE_USER, USER_TYPE.PRO_USER],
  },
  {
    to: appRoutes.userProfile,
    value: 'common:sidebar_mobile_item_2',
    icon: UserProfile,
    visibleFor: [USER_TYPE.FREE_USER],
  },
  {
    to: appRoutes.userProfile,
    value: 'common:sidebar_mobile_item_2',
    icon: UserProfilePro,
    visibleFor: [USER_TYPE.PRO_USER],
  },
  {
    to: appRoutes.pricing,
    value: 'common:sidebar_mobile_item_3',
    icon: Assets.shield,
    visibleFor: [USER_TYPE.FREE_USER],
  },
  {
    to: appRoutes.downloadAppRoute,
    value: 'common:sidebar_mobile_item_1',
    icon: Assets.mobile_navigator,
    visibleFor: [USER_TYPE.FREE_USER, USER_TYPE.PRO_USER],
  },
  {
    to: appRoutes.gallery,
    value: 'common:sidebar_mobile_item_5',
    icon: Assets.image_gallery,
    visibleFor: [USER_TYPE.FREE_USER, USER_TYPE.PRO_USER],
  },
];
