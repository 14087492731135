import { inject } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { StripeProxy } from '../../../../../domain/proxy/StripeProxy';
import { I18nService } from '../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { transient } from '../../../../../inversify/decorator';
import {
  GroupSubscriptionDeleteRequestDto
} from '../../../../../shared/dto/subscription/groupSubscription.delete.request.dto';

@transient()
export class ManageGroupTableVm extends ViewModel {

  @observable
  public anchorEl: HTMLButtonElement | null = null;

  @observable
  public open: boolean = false;

  @observable
  public selectedUserForRemoval: GroupSubscriptionUserModel | null = null;

  constructor(
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setAnchorEl = (anchorEl: HTMLButtonElement | null) => {
    this.anchorEl = anchorEl;
  }

  @action
  public setOpen = (open: boolean) => {
    this.open = open;
  }

  @action
  public setSelectedUserForRemoval = (selectedUserForRemoval: GroupSubscriptionUserModel | null) => {
    this.selectedUserForRemoval = selectedUserForRemoval;
  }

  public removeUserFromGroup = new AsyncTask(async (coupon: string | null | undefined) => {
    if (!this.selectedUserForRemoval) {
      return;
    }

    try {
      const dto: GroupSubscriptionDeleteRequestDto = {
        userId: this.selectedUserForRemoval.id,
        coupon: coupon,
      };
      const result = await this.stripeProxy.removeUserFromGroupSubscription(dto);
      if (result.ok) {
        return this.notification.success(this.i18n.t('manage_group:delete.success'));
      }

      return this.notification.error(this.i18n.t('manage_group:delete.error'));
    } catch (error) {
      console.error(`error while trying to remove user from a group subscription: ${error}`);
    }
  })

  public enabledDistrictsModal = (districts: string): boolean => {
    return districts.length > 60;
  }

  public truncateNames = (names: string, maxChars: number = 60) => {
    if (names.length <= maxChars) return names;
    return names.slice(0, maxChars).trim() + '...';
  }

}
