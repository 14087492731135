import { inject } from 'inversify';
import { makeObservable, action } from 'mobx';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { StripeProxy } from '../../../../../domain/proxy/StripeProxy';
import { I18nService } from '../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../../inversify/decorator';

@transient()
export class UserProfileFreeUserRouteVm extends ViewModel {

  constructor(
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(TrackingService) private readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public createPortalSession = async (): Promise<void> => {
    await this.tracking.track(TrackingEvent.MANAGE_SUBSCRIPTION);

    try {
      const result = await this.stripeProxy.createPortalSession.run();

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      this.notification.error(this.i18n.t('payment:errors.portal_session_fail'));
    }
    catch (error) {
      console.error(`exception while doing login. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.portal_session_error'));
    }
  }
}
