import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { UserProxy } from '../../../../domain/proxy/UserProxy';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { appRoutes } from '../../../../router/routes';

const SECONDS_TO_ENABLE_BTN = 10;

@transient()
export class DeleteAccountVm extends ViewModel {

  @observable
  public step: number = 0;

  @observable
  public open: boolean = false;

  @observable
  public seconds: number = SECONDS_TO_ENABLE_BTN;

  @observable
  public timer: ReturnType<typeof setInterval>;

  constructor(
    @inject(UserProxy) private readonly userProxy: UserProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isButtonDisabled(): boolean {
    return this.step === 0 && this.seconds > 0;
  }

  @action
  public toggleOpen = (open: boolean) => {
    this.open = open;
    this.open ? this.startTimer() : this.resetTimer();
  }

  @action
  public setStep = (step: number) => {
    this.step = step;
  }

  @action
  public setSeconds = (seconds: number) => {
    this.seconds = seconds;
  }

  public handleDeleteAccount = new AsyncTask(async () => {
    if (!this.step) {
      return this.setStep(1);
    }

    const result = await this.userProxy.deleteAccount();
    if (result.ok) {
      this.notification.success('delete_account:success');
      return this.navigate(appRoutes.login);
    }

    return this.notification.error('delete_account:error');
  })

  public resetTimer = (): void => {
    clearInterval(this.timer);
    this.setSeconds(SECONDS_TO_ENABLE_BTN);
    this.setStep(0);
  }

  public startTimer = (): void => {
    if (this.seconds > 0) {
      runInAction(() => {
        this.timer = setInterval(() => {
          this.setSeconds(this.seconds > 0 ? this.seconds - 1 : 0);
        }, 1000);
      });
    }
  }

}
