import React from 'react';

import { Button, ButtonProps, CircularProgress } from '@mui/material';

import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface IProps extends React.PropsWithChildren<unknown>, ButtonProps {
  submitting?: boolean;
}

export function NewSubmitButton(props: IProps) {
  const { theme } = useThemeContext();

  const {
    children,
    submitting = false,
    disabled = false,
    color = 'primary',
    size = 'small',
    variant = 'contained',
    ...buttonProps
  } = props;

  return (
    <Button
      type="submit"
      color={color}
      variant={variant}
      disabled={disabled || submitting}
      size={size}
      sx={{
        position: 'relative',
      }}
      {...buttonProps}
    >
      {children ? children : 'Submit'}
      {submitting && (
        <CircularProgress
          size={24}
          disableShrink
          sx={{
            color: theme.palette.text.secondary,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </Button>
  );
}

export type SubmitButtonProps = IProps;
