import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, Link, Typography } from '@mui/material';

import { useVm } from '../../../domain/hooks/useVm';
import { LoadingFallback } from '../../../LoadingFallback';
import { colors } from '../../../theme/options/palette/const/colors';
import { IconAndTitle } from '../../../toolkit/components/IconAndTitle';
import {
  AddMemberTable
} from '../../user-profile/components/manage-group/add-member/add-member-table/AddMemberTable';
import { NoDataTable } from '../../user-profile/components/manage-group/components/NoDataTable';
import {
  GroupSubscriptionStepperModal
} from '../group-discount/group-subscription-stepper/GroupSubscriptionStepperModal';
import { BuyGroupSubscriptionVm } from './BuyGroupSubscriptionVm';

export const BuyGroupSubscription = observer(function BuyGroupSubscription() {
  const { t } = useTranslation();
  const vm = useVm(BuyGroupSubscriptionVm);

  if (vm.fetchData.isBusy) {
    return <LoadingFallback />;
  }

  return <Box
    paddingY={{ xs: 7 }}
    paddingLeft={{ xs: '3%', md: '8%' }}
    paddingRight={'3%'}
    sx={{
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      minHeight: '100vh',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
    }}
  >
    <Box display="flex" flexDirection="column">
      <GroupSubscriptionStepperModal />

      <IconAndTitle title={t('buy_group_subscription:title')} subtitle={t('buy_group_subscription:subtitle')} />

      <Box display={'flex'} flexDirection={'column'} rowGap={3} maxWidth={{ xs: '100%', lg: '90%' }}>

        {/* HEADER */}
        <Grid container direction={'row'} rowSpacing={0.5}>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={'bolder'}>{t('buy_group_subscription:create_group.title')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={'normal'}>
              {t('buy_group_subscription:create_group.body_1')}
              &nbsp;
              <Link variant={'h6'}
                fontWeight={'normal'}
                target={'_blank'}
                href={t('links:learn_how_we_bill')}
                sx={{ textDecoration: 'underline' }}
              >
                {t('buy_group_subscription:create_group.body_2')}
              </Link>
            </Typography>
          </Grid>
        </Grid>

        {/* SUMMARY_1 */}
        <Grid container direction={'row'} rowSpacing={0.5} color={colors.darkFont} visibility={vm.relatedMembers.length > 1 ? 'visible' : 'hidden'}>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={'normal'}>
              {`${vm.selectedUsers.size} ${t('buy_group_subscription:members_1')}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* per member/year + total per year */}
            <Typography variant='h6' fontWeight={'normal'}>
              {`${vm.groupPricePerMemberPerYear.toFixed(2)}${vm.currency} ${t('buy_group_subscription:members_2', { price: `${vm.groupTotalPrice.toFixed(2)}${vm.currency}` })}`}
            </Typography>
          </Grid>
        </Grid>

        {/* SUMMARY_2 */}
        <Grid item container xs={12} justifyContent={'space-between'}>
          <Grid item xs={12} sm={8} visibility={vm.selectedUsers.size > 1 ? 'visible' : 'hidden'}>
            {/* SUMMARY WITHOUT DISCOUNT */}
            <Typography variant='h6' fontWeight={'bold'} color={colors.darkFont}>
              {t('buy_group_subscription:summary.free_user', {
                count: vm.selectedUsers.size,
                price: `${vm.groupTotalPriceAfterDiscount.toFixed(2)}${vm.currency}`
              })}
            </Typography>
            {/* SUMMARY WITH DISCOUNT */}
            {vm.groupTotalDiscount !== 0 && <Typography variant='h6' fontWeight={'normal'} color={colors.green.hunterGreen}>
              {t('buy_group_subscription:summary.pro_user_1', {
                count: vm.selectedUsers.size,
                discount: `${vm.groupTotalDiscount.toFixed(2)}${vm.currency}`
              })}
              &nbsp;
              <Link variant={'h6'}
                fontWeight={'normal'}
                color={colors.green.hunterGreen}
                target={'_blank'}
                href={t('links:discount_explanation')}
                sx={{ textDecoration: 'underline' }}
              >
                {t('buy_group_subscription:summary.pro_user_2')}
              </Link>
            </Typography>
            }
          </Grid>
          <Grid item xs={12} sm={4} pt={{ xs: 2, sm: 0 }} px={{ xs: 4, sm: 0 }} textAlign={{ xs: 'center', sm: 'end' }}>
            <Button
              onClick={vm.createCheckoutSession.run}
              variant='contained'
              disabled={vm.selectedUsers.size < 2}
              sx={{
                bgcolor: colors.orange[400],
                '&:hover': {
                  bgcolor: colors.orange[600]
                },
                borderRadius: 0,
                fontSize: 15,
                px: 2,
                minWidth: 150,
                height: 50,
                boxShadow: '3px 6px 6px #0000003D',
              }}
            >
              {t('buy_group_subscription:checkout_button')}
            </Button>
          </Grid>
        </Grid>

        {/* TABLE */}
        <Grid container direction={'row'} mt={2}>
          {vm.relatedMembers.length > 1
            ? <AddMemberTable relatedMembers={vm.relatedMembers} handleCheckboxChange={vm.handleCheckboxChange} />
            : <NoDataTable message={t('manage_group:add_members.table.no_related_members')} />
          }
        </Grid>

      </Box>

    </Box>
  </Box>;

});
