import { ReactSVG } from 'react-svg';

interface ISVGWrapperProps {
  icon: string;
  fill: string | undefined;
  width?: number;
  height?: number;
}

export const SvgWrapper = function SvgWrapper(props: ISVGWrapperProps) {

  return (
    <ReactSVG
      className='wrapper'
      beforeInjection={(svg) => {
        const elements = svg.querySelectorAll('svg [fill="currentColor"]');
        elements.forEach(e => {
          if (props.fill) {
            e.setAttribute('fill', props.fill);
          }
        });
        if (props.height) {
          svg.setAttribute('height', `${props.height}px`);
        }
        if (props.width) {
          svg.setAttribute('width', `${props.width}px`);
        }
      }}
      src={props.icon}
      wrapper='div'
      // * Look this github issue: https://github.com/tanem/react-svg/issues/24#issuecomment-1462263154
      afterInjection={svg => {
        // * position icons to the center of div height (it has two divs wrapping svg so this is necessary https://github.com/tanem/react-svg/issues/24)
        const parent = svg.parentNode as HTMLElement;
        if (parent) {
          parent.style.display = 'flex';
          parent.style.flexDirection = 'row';
          parent.style.alignItems = 'center';
        }

      }}
    />
  );
};
