import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box, Checkbox, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Tooltip, Typography, useMediaQuery
} from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { GroupSubscriptionUserModel } from '../../../../../../domain/model/GroupSubscriptionUserModel';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { USER_ROLE_IN_GROUP } from '../../../../../../shared/enum';
import { colors } from '../../../../../../theme/options/palette/const/colors';
import { AreasColumn } from '../../components/AreasColumn';
import { UserColumn } from '../../components/UserColumn';

export interface IGroupTableProps {
  relatedMembers: GroupSubscriptionUserModel[];
  handleCheckboxChange: (checked: boolean, relatedMember: GroupSubscriptionUserModel) => void;
  voucherSeatsLeft?: number;
  selectedUsers?: Set<GroupSubscriptionUserModel>;
}

export const AddMemberTable = observer(function AddMemberTable({ relatedMembers, handleCheckboxChange, voucherSeatsLeft, selectedUsers = new Set() }: IGroupTableProps) {

  const { t } = useTranslation();
  const sessionStore = useContainer().get(SessionStore);
  const isSmartphoneScreen = useMediaQuery('(max-width:750px)');

  const isPartOfGroup = (relatedMember: GroupSubscriptionUserModel) =>
    relatedMember.userRoleInGroup === USER_ROLE_IN_GROUP.MEMBER ||
    relatedMember.userRoleInGroup === USER_ROLE_IN_GROUP.OWNER;

  const runningSubscriptionInfo = (relatedMember: GroupSubscriptionUserModel) => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="200px"
      p={1}
      border="0.5px solid black"
    >
      <Typography
        textAlign="center"
        variant="caption"
        fontWeight="bold"
        color={colors.blue}
      >
        {isPartOfGroup(relatedMember)
          ? t('manage_group:add_members.part_of_group.status_tooltip.title')
          : t('manage_group:add_members.status_tooltip.title')
        }
      </Typography>
      <Typography textAlign="center" variant="caption">
        {isPartOfGroup(relatedMember)
          ? t('manage_group:add_members.part_of_group.status_tooltip.body')
          : t('manage_group:add_members.status_tooltip.body')
        }
      </Typography>
    </Box>
  );

  const shouldDisplayRunningSubscriptionIcon = (relatedMember: GroupSubscriptionUserModel) => (Boolean(relatedMember.subscription) && relatedMember.id !== sessionStore.userId);

  const shouldBeDisabled = (relatedMember: GroupSubscriptionUserModel) => {
    const isUserAlreadySelected = selectedUsers.has(relatedMember);

    if (voucherSeatsLeft && voucherSeatsLeft <= selectedUsers.size && !isUserAlreadySelected) {
      return true;
    }

    return relatedMember.id === sessionStore.userId || isPartOfGroup(relatedMember);
  };

  const renderStatusInfo = (user: GroupSubscriptionUserModel) => {
    const { subscription } = user;

    if (!subscription) {
      return (
        <Typography variant="subtitle2" fontWeight="normal">
          {t('manage_group:add_members.table.status.free')}
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="subtitle2" fontWeight="normal">
          {t('manage_group:add_members.table.status.pro')}
        </Typography>
        <Typography variant="subtitle2" fontWeight="normal">
          {subscription.unsubscribeDetectedAt
            ? t('manage_group:add_members.table.status.expires')
            : t('manage_group:add_members.table.status.renews')}
          &nbsp;{subscription.expiresAt && format(new Date(subscription.expiresAt), 'dd.MM.yyyy.')}
        </Typography>
      </>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell variant="head" sx={{
              width: { xs: '1%', md: '3%' },
              padding: { xs: 0, md: 2 },
              textAlign: { xs: 'left', lg: 'center' },
              fontWeight: 'bold'
            }} />
            <TableCell variant="head" sx={{
              width: isSmartphoneScreen ? '60%' : '43%',
              paddingLeft: { xs: 1, md: 2 },
              paddingRight: { xs: 2 },
              fontWeight: 'bold'
            }}>
              {t('manage_group:add_members.table_head.column_2')}
            </TableCell>
            {!isSmartphoneScreen &&
              <TableCell variant="head" sx={{
                px: { xs: 3, md: 1 },
                width: '35%',
                fontWeight: 'bold'
              }}>
                {t('manage_group:add_members.table_head.column_3')}
              </TableCell>
            }
            <TableCell variant="head" sx={{
              width: { xs: '25%', lg: '15%' },
              paddingRight: 0,
              fontWeight: 'bold'
            }}>
              {t('manage_group:add_members.table_head.column_4')}
            </TableCell>
            <TableCell variant="head" sx={{ flexGrow: 1, fontWeight: 'bold' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {relatedMembers.map((relatedMember) => (
            <TableRow
              key={relatedMember.id}
              sx={[
                {
                  '&:last-child td, &:last-child th': { border: 0 },
                },
                shouldBeDisabled(relatedMember)
                  ? {
                    '& > *:not(:first-of-type):not(:last-of-type)': {
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }
                  }
                  : { opacity: 1, pointerEvents: 'auto' }
              ]}
            >
              <TableCell sx={{
                width: { xs: '1%', md: '3%' },
                padding: { xs: 0, md: 2 },
                bgcolor: 'transparent',
                textAlign: 'right',
              }}>
                <Checkbox
                  size="small"
                  defaultChecked={relatedMember.id === sessionStore.userId}
                  onChange={(_event, checked) => handleCheckboxChange(checked, relatedMember)}
                  disabled={shouldBeDisabled(relatedMember)}
                />
              </TableCell>
              <TableCell sx={{
                width: isSmartphoneScreen ? '60%' : '43%',
                paddingLeft: { xs: 1, md: 2 },
                paddingRight: { xs: 2 },
              }}>
                <UserColumn user={relatedMember} />
              </TableCell>
              {!isSmartphoneScreen &&
                <TableCell sx={{ px: { xs: 3, md: 1 }, width: '35%' }}>
                  <AreasColumn participant={relatedMember} />
                </TableCell>
              }
              <TableCell sx={{ width: { xs: '25%', lg: '15%' }, paddingRight: 0 }}>
                {renderStatusInfo(relatedMember)}
              </TableCell>
              <TableCell sx={{ flexGrow: 1, textAlign: 'left' }}>
                {shouldDisplayRunningSubscriptionIcon(relatedMember) && (
                  <Tooltip title={runningSubscriptionInfo(relatedMember)}>
                    {isPartOfGroup(relatedMember)
                      ? <Icon component={RemoveCircleOutlineIcon} htmlColor={colors.error} />
                      : <Icon component={InfoIcon} htmlColor={colors.blue} />
                    }
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
