import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useContainer } from '../domain/hooks/useContainer';
import { SessionStore } from '../domain/store/SessionStore';
import { LoadingFallback } from '../LoadingFallback';
import { SidebarMenu } from '../modules/menu/SidebarMenu';
import { PaymentIssueBlockerModal } from '../modules/payment-modals/PaymentIssueBlockerModal';
import { oldDesignTheme } from '../theme/oldDesignTheme';
import { sidebarNavigationList } from '../toolkit/constants/SidebarNavigationList';
import { View } from '../toolkit/layout/View';
import { appRoutes } from './routes';

export const AuthenticatedRoute = observer(function AuthenticatedRoutes(props: React.PropsWithChildren<unknown>) {
  const sessionStore = useContainer().get(SessionStore);
  const location = useLocation();

  const renderPaymentIssueBlocker = useMemo(() => {
    const irrelevantRoutes = [appRoutes.successfulPayment];
    const isOnIrrelevantRoute = irrelevantRoutes.some(route => route === location.pathname);

    return !isOnIrrelevantRoute && sessionStore.hasPaymentIssues;
  }, [location.pathname, sessionStore.hasPaymentIssues]);

  if (sessionStore.recoverSession.isBusy) {
    return <LoadingFallback />;
  }

  if (!sessionStore.isAuthenticated) {
    /**
     * Redirect the user to the /login page but save the current location they were trying to reach when they were redirected.
     * This allows us to send the user to that page after they log in, which provides a better user experience than
     * dropping them off on the home page.
    */
    return <Navigate to={`${appRoutes.login}${location.search}`} state={{ from: location }} />;
  }

  return (
    <View bgcolor={oldDesignTheme.palette?.background?.paper}>
      {renderPaymentIssueBlocker && <PaymentIssueBlockerModal />}
      {<SidebarMenu items={sidebarNavigationList}>
        {props.children}
      </SidebarMenu>}
    </View>
  );
});
