import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box, Container, Grid, IconButton, Modal, Tooltip, Typography, useTheme
} from '@mui/material';

import { FitContainerLoadingFallback } from '../../../../../../FitContainerLoadingFallback';
import { colors } from '../../../../../../theme/options/palette/const/colors';
import { palette } from '../../../../../../theme/options/palette/palette';
import { SubmitButton } from '../../../../../../toolkit/components/SubmitButton';
import { currencySymbol } from '../../../../../../util/CurrencyHelper';
import { AddMemberRouteVm } from '../AddMemberRouteVm';
import { SummaryTable } from './SummaryTable';

export type AddMemberModalRef = {
  open: () => void,
  close: () => void,
}

interface AddMemberModalProps {
  addMemberVm: AddMemberRouteVm;
}

export const AddMemberModal = observer(
  React.forwardRef<AddMemberModalRef, AddMemberModalProps>(
    function AddMemberModal(props: AddMemberModalProps, ref) {

      const theme = useTheme();
      const { t } = useTranslation();
      const [open, setOpen] = useState(false);

      useImperativeHandle(ref, () => ({
        open() {
          setOpen(true);
        },
        close() {
          setOpen(false);
        }
      }));

      const handleClose = useCallback(() => {
        setOpen(false);
      }, []);

      // * capitalized first letter of brand
      const brand = useMemo(() => {
        return props.addMemberVm.paymentMethod ? props.addMemberVm.paymentMethod.brand?.replace(/^(.)(.*)$/, (match, firstChar, rest) => `${firstChar.toUpperCase()}${rest}`) : '';
      }, [props.addMemberVm.paymentMethod]);

      const tooltipDiscountExplanation = useMemo(() => (
        <Tooltip
          placement="top"
          title={<Box
            display="flex"
            flexDirection="row"
            justifyContent={'space-between'}
          >
            {t('manage_group:add_members.modal.discount_info', {
              discount: `${Math.abs(props.addMemberVm.upcomingInvoice.startingBalance)}${currencySymbol(props.addMemberVm.upcomingInvoice.currency)}`
            })}
          </Box>}
          componentsProps={{
            tooltip: {
              sx: {
                minWidth: 'auto',
                padding: 1,
                border: '1px solid black',
              }
            }
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: 15, alignSelf: 'center', color: colors.green[400], bgcolor: colors.paper, cursor: 'pointer' }} />
        </Tooltip>), [props.addMemberVm.upcomingInvoice.currency, props.addMemberVm.upcomingInvoice.startingBalance, t]);

      return (
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: '23vh'
          }}
        >
          <Grid container
            rowGap={4}
            sx={{
              position: 'relative',
              backgroundColor: palette.background?.paper,
              border: '1px solid #707070',
              borderRadius: '5px',
              boxShadow: theme.shadows[5],
              padding: 4,
              width: { xs: '90%', md: '75%', lg: '65%', xl: '55%' },
            }}>
            {/* HEADER */}
            <Grid container direction="row" justifyContent={'center'} alignContent={'center'}>
              <Grid item xs={11} textAlign={'center'} alignSelf={'center'}>
                <Typography variant='h5'>{t('manage_group:add_members.modal.title')}</Typography>
              </Grid>
              <Box sx={{
                position: 'absolute',
                right: 5,
                top: 10,
              }}>
                <IconButton color="inherit" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>

            {props.addMemberVm.getUpcomingInvoice.isBusy || props.addMemberVm.getUpcomingInvoiceLineItems.isBusy
              ? <FitContainerLoadingFallback />
              : <React.Fragment>

                <Container maxWidth="md">
                  <SummaryTable
                    oldQuantity={props.addMemberVm.oldQuantity}
                    newQuantity={props.addMemberVm.newQuantity}
                    oldTotal={props.addMemberVm.oldTotal}
                    newTotal={props.addMemberVm.newTotal}
                    taxRate={props.addMemberVm.taxRate}
                  />

                  {/* SUMMARY */}
                  <Box px={2}>
                    <Grid container mt={5}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>{t('manage_group:add_members.modal.summary_1')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>{props.addMemberVm.membersAndExtendedSubscriptionInfo}</Typography>
                      </Grid>
                    </Grid>
                    {/* UPDATE INVOICE */}
                    <Grid container marginY={3} justifyContent={'flex-end'}>
                      <Grid item container xs={8} spacing={2}>
                        <Grid item xs={7} container direction={'column'} textAlign={'end'} lineHeight={0.1}>
                          {/* TOTAL PRICE IN CURRENT YEAR */}
                          <Grid item>
                            <Typography variant='subtitle1' fontWeight={'bold'}>{t('manage_group:add_members.modal.invoice_1')}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                              {props.addMemberVm.upcomingInvoicePartialAmount}&nbsp;
                              {props.addMemberVm.upcomingInvoice.startingBalance !== 0 && tooltipDiscountExplanation}
                            </Typography>
                          </Grid>
                          {/* BILLED ON DATE */}
                          <Grid item>
                            <Typography variant='caption'>
                              {/* // TODO: remove *1000 when backends changes to ISO 8601 */}
                              {t('manage_group:add_members.modal.invoice_2')}&nbsp;{format(new Date(props.addMemberVm.upcomingInvoice.created * 1000), 'dd.MM.yyyy.')}
                            </Typography>
                          </Grid>
                          <Grid item height={15} />
                          {/* NEXT BILLING DATE / EXPIRES AT DATE */}
                          <Grid item>
                            <Typography variant='caption'>{t('manage_group:add_members.modal.invoice_3')}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='caption'>{props.addMemberVm.manageGroupVm.expiresAt}</Typography>
                          </Grid>
                          {/* NEXT BILLING AMOUNT */}
                          <Grid item>
                            <Typography variant='caption'>{t('manage_group:add_members.modal.invoice_4')}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='caption'>{props.addMemberVm.upcomingInvoiceFullAmount}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={5} alignContent={'flex-start'}>
                          <Grid item xs={12}>
                            <SubmitButton
                              onClick={props.addMemberVm.updateGroupSubscription.run}
                              variant='contained'
                              submitting={props.addMemberVm.updateGroupSubscription.isBusy}
                              sx={{
                                minWidth: '100%',
                                borderRadius: 0,
                                fontSize: 15,
                                p: 2,
                                minHeight: 50,
                                boxShadow: '3px 6px 6px #0000003D',
                                bgcolor: colors.orange[400],
                                '&:hover': {
                                  bgcolor: colors.orange[600]
                                }
                              }}
                            >
                              {t('manage_group:button.update_subscription')}
                            </SubmitButton>
                          </Grid>
                          <Grid item xs mt={1}>
                            <Typography fontSize={'10px'} color={colors.blueGrey[300]}>
                              {t('manage_group:update_disclaimer.body')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* PAYMENT METHOD */}
                    {props.addMemberVm.paymentMethod && <Grid container color={'GrayText'}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2' fontWeight={'bold'}>{t('manage_group:add_members.modal.payment_method')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle2' fontWeight={'bold'}>xxxx{props.addMemberVm.paymentMethod.last4} {brand}</Typography>
                      </Grid>
                    </Grid>}
                  </Box>

                </Container>

              </React.Fragment>}

          </Grid>
        </Modal>
      );
    })
);
