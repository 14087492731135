import { format } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { GroupSubscriptionModel } from '../../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { StripeProxy } from '../../../../domain/proxy/StripeProxy';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { scoped } from '../../../../inversify/decorator';
import {
  STRIPE_SUBSCRIPTION_STATUS
} from '../../../../shared/enum/stripe/stripeSubscriptionStatus.enum';

@scoped()
export class ManageGroupRouteVm extends ViewModel {

  @observable
  public groupSubscriptionInfo: GroupSubscriptionModel = new GroupSubscriptionModel();

  constructor(
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await Promise.all([
      this.getSubscriptionInfo.run(),
    ]);
  }

  @computed
  public get dataLoadingInProgress(): boolean {
    return this.getSubscriptionInfo.isBusy;
  }

  @computed
  public get isIncompleteOrPastDue(): boolean {
    const { groupSubscriptionStatus } = this.groupSubscriptionInfo;
    return groupSubscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE || groupSubscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.PAST_DUE;
  }

  @computed
  public get groupSubscriptionStatusTranslation(): string {
    const { groupSubscriptionStatus } = this.groupSubscriptionInfo;

    if (groupSubscriptionStatus) {
      const i18nKey = `manage_group:group_status.${groupSubscriptionStatus}`;
      return this.i18n.t(i18nKey);
    } else {
      return this.i18n.t('manage_group:group_status.active');
    }
  }

  @computed
  public get groupSubscriptionActionTranslation(): string {
    const { canceledAt } = this.groupSubscriptionInfo;
    const i18nKey = `manage_group:group_subscription_action.${canceledAt ? 'canceled' : 'active'}`;

    return this.i18n.t(i18nKey);
  }

  @computed
  public get allParticipantsOfGroup(): GroupSubscriptionUserModel[] {
    if (!this.groupSubscriptionInfo.owner) return [];

    return [this.groupSubscriptionInfo.owner, ...this.groupSubscriptionInfo.members];
  }

  @computed
  public get expiresAt(): string | undefined {
    if (this.groupSubscriptionInfo.expiresAt) {
      return format(new Date(this.groupSubscriptionInfo.expiresAt), 'dd.MM.yyyy.');
    }
  }

  @action
  public setGroupSubscriptionInfo = (groupSubscriptionInfo: GroupSubscriptionModel) => {
    this.groupSubscriptionInfo = groupSubscriptionInfo;
  }

  @action
  public createPortalSession = async (): Promise<void> => {
    await this.tracking.track(TrackingEvent.MANAGE_SUBSCRIPTION);

    try {
      const result = await this.stripeProxy.createPortalSession.run();

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      this.notification.error(this.i18n.t('payment:errors.portal_session_fail'));
    }
    catch (error) {
      console.error(`exception while creating portal session. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.portal_session_error'));
    }
  }

  public getSubscriptionInfo = new AsyncTask(async () => {
    try {
      const response = await this.stripeProxy.getGroupSubscriptionInfo();

      if (response.ok) {
        return this.setGroupSubscriptionInfo(response.data);
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_group_subscription_info'));
    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_group_subscription_info'));
    }
  })

  public handleClickOnALink = () => {
    this.tracking.track(TrackingEvent.PROFILE_MULTIPLE_SUBSCRIPTIONS_CANCEL);
  };

}
