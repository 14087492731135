import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmailIcon from '@mui/icons-material/Email';
import { Box, Grid, Typography } from '@mui/material';

import { useVm } from '../../../domain/hooks/useVm';
import { appRoutes } from '../../../router/routes';
import { oldDesignTheme } from '../../../theme/oldDesignTheme';
import { SubmitButton } from '../../../toolkit/components/SubmitButton';
import { AppleLoginButton } from '../components/apple-login/AppleLoginButton';
import { FacebookLoginButton } from '../components/facebook-login/FacebookLoginButton';
import { GoogleLoginButton } from '../components/google-login/GoogleLoginButton';
import { RegistrationRouteVm } from './RegistrationRouteVm';

export function RegistrationRoute() {

  const { t } = useTranslation();
  const vm = useVm(RegistrationRouteVm);
  const loginButtonDimensions = { width: '278px', height: '48px' };

  return (
    <Box>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <Box mb={2}>
            <Typography color='textPrimary' textAlign='center' variant="h1">
              {t('auth:register_registration_title')}
            </Typography>
            <Typography color='textPrimary' textAlign='center' variant="subtitle1">
              {t('auth:choose_registration_type')}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <GoogleLoginButton sx={loginButtonDimensions} />
        </Grid>
        <Grid item>
          <FacebookLoginButton sx={loginButtonDimensions} />
        </Grid>
        <Grid item>
          <AppleLoginButton sx={loginButtonDimensions} />
        </Grid>
        <Grid item>
          <Typography fontSize={15} fontWeight={'normal'}>
            {t('auth:intro_alternative_login')}
          </Typography>
        </Grid>
        <Grid item>
          <SubmitButton
            onClick={() => vm.onRegisterClick()}
            startIcon={<EmailIcon />}
            sx={loginButtonDimensions}
          >
            {t('auth:register_email_form_email')}
          </SubmitButton>
        </Grid>
        <Grid item>
          <Box marginTop={3}>
            <Typography color="textPrimary" fontSize={15} fontWeight={'normal'}>
              {t('auth:intro_cta_login')}{' '}
              <Link to={appRoutes.login} style={{
                color: oldDesignTheme.palette.text.tertiary,
                textDecoration: 'none',
                fontSize: '15px',
                fontWeight: 'bold',
              }}>
                {t('auth:body_login')}
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

}
