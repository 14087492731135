
import { differenceInDays } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';
import { StripeProxy } from '../../../domain/proxy/StripeProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import {
  GroupSubscriptionPostRequestDto
} from '../../../shared/dto/subscription/groupSubscription.post.request.dto';
import { GroupPricingVm } from '../GroupPricingVm';

@transient()
export class BuyGroupSubscriptionVm extends GroupPricingVm {

  @observable
  public relatedMembers: GroupSubscriptionUserModel[] = [];

  @observable
  public selectedUsers: Set<GroupSubscriptionUserModel> = new Set();

  constructor(
    @inject(StripeProxy) protected override readonly stripeProxy: StripeProxy,
    @inject(I18nService) protected override readonly i18n: I18nService,
    @inject(NotificationService) protected override readonly notification: NotificationService,
    @inject(SessionStore) protected override readonly sessionStore: SessionStore,
  ) {
    super(stripeProxy, i18n, notification, sessionStore);
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.fetchData.run();
  }

  public override fetchData = new AsyncTask(async () => {
    await Promise.all([
      this.getProducts.run(),
      this.getRelatedMembers.run(),
    ]);
  })

  @computed
  public get groupPricePerMemberPerYear(): number {
    return this.groupTotalPrice / this.selectedUsers.size;
  }

  @computed
  public get groupTotalPrice(): number {
    return this.selectedUsers.size === 1
      ? this.priceForOneUser
      : this.priceForOneUser + this.priceForEveryNextUser * (this.selectedUsers.size - 1);
  }

  /**
   * A user who wants to buy a subscription will only receive a discount if he already has a PRO yearly subscription through AppStore, PlayStore, or Stripe. Otherwise, no discount will be applied.
   * The user can only add free members to a group, so no discount will be given for additional users apart from the user himself.
  */
  @computed
  public get groupTotalDiscount(): number {
    const discountCandidate = Array.from(this.selectedUsers).find(user => user && user.id === this.sessionStore.userId && user.isYearlySubscription);
    if (discountCandidate && discountCandidate.subscription) {
      const today = new Date();
      const expirationDate = discountCandidate.subscription.expiresAt;
      const daysLeftUntilRenewal = differenceInDays(expirationDate!, today);

      return daysLeftUntilRenewal / 365 * this.priceForOneUser;
    }

    return 0;
  }

  @computed
  public get groupTotalPriceAfterDiscount(): number {
    return this.groupTotalPrice - this.groupTotalDiscount;
  }

  @action
  public setRelatedMembers = (relatedMembers: GroupSubscriptionUserModel[]) => {
    this.relatedMembers = this.reorderUsersWithLoggedInUserFirst(relatedMembers);
  }

  @action
  public setSelectedUsers = (selectedUsers: Set<GroupSubscriptionUserModel>) => {
    this.selectedUsers = selectedUsers;
  }

  public handleCheckboxChange = (checked: boolean, relatedMember: GroupSubscriptionUserModel) => {
    const updatedSelectedUsers = new Set(this.selectedUsers);

    if (checked) {
      updatedSelectedUsers.add(relatedMember);
    } else {
      updatedSelectedUsers.delete(relatedMember);
    }

    this.setSelectedUsers(updatedSelectedUsers);
  }

  private reorderUsersWithLoggedInUserFirst = (relatedMembers: GroupSubscriptionUserModel[]) => {
    const index = relatedMembers.findIndex(member => member.id === this.sessionStore.userId);

    if (index !== -1) {
      const [member] = relatedMembers.splice(index, 1);
      relatedMembers.unshift(member);
    }
    this.setSelectedUsers(new Set([relatedMembers[0]]));
    return relatedMembers;
  }

  private getRelatedMembers = new AsyncTask(async () => {
    try {
      const response = await this.stripeProxy.getRelatedMembers();

      if (response.ok) {
        return this.setRelatedMembers(response.data);
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_related_members'));
    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_related_members'));
    }
  });

  public createCheckoutSession = new AsyncTask(async (): Promise<void> => {
    try {
      if (!this.product) {
        throw new Error('something went wrong');
      }

      const groupSubscriptionPostRequestDto: GroupSubscriptionPostRequestDto = {
        productId: this.product.id,
        priceId: this.product.price.priceId,
        members: Array.from(this.selectedUsers, selectedUser => selectedUser.id),
        quantity: this.selectedUsers.size,
        discount: this.groupTotalDiscount,
        couponId: this.product.coupon?.id,
      };

      const result = await this.stripeProxy.createCheckoutSession(groupSubscriptionPostRequestDto);

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      this.notification.error(this.i18n.t('payment:errors.checkout_session_fail'));
    }
    catch (error) {
      console.error(`exception while doing login. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.checkout_session_error'));
    }
  });

}
