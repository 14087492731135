import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { StripeProxy } from '../../../domain/proxy/StripeProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import { GroupPricingVm } from '../GroupPricingVm';

@transient()
export class GroupDiscountVm extends GroupPricingVm {

  public minimumAmountOfSubscriptions: number = 1;

  @observable
  public quantity: number = 1;

  constructor(
    @inject(StripeProxy) protected override readonly stripeProxy: StripeProxy,
    @inject(I18nService) protected override readonly i18n: I18nService,
    @inject(NotificationService) protected override readonly notification: NotificationService,
    @inject(SessionStore) protected override readonly sessionStore: SessionStore,
  ) {
    super(stripeProxy, i18n, notification, sessionStore);
    makeObservable(this);
  }

  @computed
  public get webWidget() {
    /** Zendesk help chat widget id */
    return document.getElementById('launcher');
  }

  @computed
  public get singlePricePerUser(): number {
    return this.quantity === 1 ? this.priceForOneUser : this.priceForEveryNextUser;
  }

  @computed
  public get regularPrice(): number {
    return this.priceForOneUser * this.quantity;
  }

  @computed
  public get savings(): number {
    return this.regularPrice - this.groupTotalPrice;
  }

  @computed
  public get groupPricePerMemberPerYear(): number {
    return this.groupTotalPrice / this.quantity;
  }

  @computed
  public get groupTotalPrice(): number {
    return this.quantity === 1
      ? this.priceForOneUser
      : this.priceForOneUser + this.priceForEveryNextUser * (this.quantity - 1);
  }

  @action
  public handleCounter = (quantity: number) => {
    this.quantity = quantity;
  };

}
