import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { PoiCheckinHistoryModel } from '../../../../../../domain/model/PoiCheckinHistoryModel';
import { PoiProxy } from '../../../../../../domain/proxy/PoiProxy';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { env } from '../../../../../../env';
import { transient } from '../../../../../../inversify/decorator';
import { Types } from '../../../../../../inversify/types';
import { appRoutes } from '../../../../../../router/routes';
import { FEATURE } from '../../../../../../shared/enum';
import { ViewCheckInsProps } from './ViewCheckIns';

@transient()
export class ViewCheckInsVm extends ViewModel<ViewCheckInsProps> {

  @observable
  public poiCheckInHistory: PoiCheckinHistoryModel[] = [];

  constructor(
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.getPoiCheckinHistory.run();
  }

  @computed
  public get currentlyCheckedInUsers(): PoiCheckinHistoryModel[] {
    return this.poiCheckInHistory.filter(user => !user.to);
  }

  @computed
  public get previouslyCheckedInUsers(): PoiCheckinHistoryModel[] {
    return this.poiCheckInHistory.filter(user => user.to);
  }

  @computed
  public get hasProCheckinList() {
    return this.session.hasFeatureEnabled(FEATURE.CHECKIN_TAG_EXPORT);
  }

  @action
  public setPoiCheckInHistory = (checkInHistory: PoiCheckinHistoryModel[]) => {
    this.poiCheckInHistory = checkInHistory;
  }

  public getPoiCheckinHistory = new AsyncTask(async () => {
    try {
      const result = await this.poiProxy.getCheckinHistory(this.props.poiId);
      if (result.ok) {
        return this.setPoiCheckInHistory(result.data);
      }
      this.notification.error(this.i18n.t('check_ins:error.get_checkin_history'));
    } catch {
      console.error('something went wrong while trying to fetch checkin history');
    }
  })

  private downloadCheckinHistory = () => {
    const url = `${env.api}/checkin-list/download/poi/${this.props.poiId}?token=${this.session.session?.sessionToken}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop() ?? '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private showProPage = () => {
    this.navigate(appRoutes.pricing);
  }

  public onDownloadClick = () => {
    if (this.hasProCheckinList) {
      return this.downloadCheckinHistory();
    }

    this.showProPage();
  }

  // * there was a problem with exposing response headers in JS code so we don't use this anymore
  /* public downloadCheckinHistory = new AsyncTask(async () => {
    try {
      const response = await this.poiProxy.downloadCheckinHistory(this.props.poiId);

      if (response.status === 200) {
        const contentDispositionHeader = response.headers['content-disposition'];
        const fileNameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);

        if (fileNameMatch) {
          const fileName = fileNameMatch[1].replace(/['"]/g, '');

          const file = new File(
            [new Blob([response.data], { type: response.headers['content-type'] })],
            fileName,
            { type: response.headers['content-type'] }
          );

          const fileUrl = URL.createObjectURL(file);

          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = file.name;

          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(fileUrl);
          document.body.removeChild(link);
          return;
        }
      }
      return this.notification.error(this.i18n.t('check_ins:error.download_checkin_history'));
    } catch (error) {
      console.error('Something went wrong while trying to export checkin list: ', error);
      return this.notification.error(this.i18n.t('check_ins:error.download_checkin_history'));
    }
  }) */

}
