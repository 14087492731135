import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  Box, Checkbox, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material';

import { Assets } from '../../../../../../assets';
import { useInstance } from '../../../../../../domain/hooks/useInstance';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { appRoutes } from '../../../../../../router/routes';
import {
  CUSTOM_MARK_COLOR, CUSTOM_MARK_ICON, FEATURE, POI_TYPE
} from '../../../../../../shared/enum';
import { getCustomMarkIcon, poiUIModels } from '../../../../../../util/PoiUIModels';
import { PoiBackground } from './PoiBackground';
import { PoiTypeButton } from './PoiTypeButton';

interface IProps {
  poi: PoiModel;
  weatherPoisCount: number;
  onClose: () => void;
}

export const PoiTypeSelection = observer(function PoiTypeSelection(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notification = useInstance(NotificationService);
  const session = useInstance(SessionStore);
  const selectedType = props.poi.type ? poiUIModels[props.poi.type] : null;

  const customMarkIcons: CUSTOM_MARK_ICON[] = useMemo(() => {
    return Object.keys(CUSTOM_MARK_ICON).filter((icon) => ![
      CUSTOM_MARK_ICON.BIOTOPE,
      CUSTOM_MARK_ICON.HEART,
      CUSTOM_MARK_ICON.HIGHSTAND_LADDER,
    ].includes(icon as CUSTOM_MARK_ICON)) as CUSTOM_MARK_ICON[];
  }, []);

  return (
    <Box paddingX={4} paddingY={2} width="100%">
      {selectedType && <Grid container>
        {/* poi type summary */}
        <Grid item container marginBottom={2} direction="row" width={'100%'} alignItems={'center'} spacing={1}>
          {/* selected type */}
          <Grid item flex={1} height={'108px'}>
            <PoiTypeButton
              text={t(selectedType.i18nKey)}
              icon={selectedType.icon}
              imgStyle={{ height: '42px' }}
              onClick={() => props.poi.setType(undefined)}
            />
          </Grid>
          {/* selected background */}
          {props.poi.customMark?.background && <Grid item flex={1} height={'108px'}>
            <PoiTypeButton
              component={<PoiBackground color={props.poi.customMark.background} />}
              onClick={() => props.poi.customMark?.setBackground(null)}
            />
          </Grid>}
          {/* selected foreground */}
          {props.poi.customMark?.foreground && <Grid item flex={1} height={'108px'}>
            <PoiTypeButton
              icon={getCustomMarkIcon(props.poi.customMark.foreground as CUSTOM_MARK_ICON)}
              imgStyle={{ height: '25px', marginBottom: '10px' }}
              text={t(`poi:custom_poi_section.labels.${props.poi.customMark.foreground.toLowerCase()}`)}
              onClick={() => props.poi.customMark?.setForeground(null)}
            />
          </Grid>}
        </Grid>
      </Grid>}
      {/* poi type selection */}
      {!props.poi.type && <Grid container spacing={2} justifyContent={'flex-start'}>
        {Object.keys(poiUIModels).map((type: string) => {
          const model = poiUIModels[type];

          return <Grid item sm={12} md={6} xl={4} key={`poi-type-${type}`}>
            <PoiTypeButton
              text={t(model.i18nKey)}
              icon={(!session.hasFeatureEnabled(FEATURE.CUSTOM_MARKS) && type === POI_TYPE.CUSTOM) ? Assets.shield : model.icon}
              imgStyle={{ width: '35px', height: '35px' }}
              onClick={() => {
                if (!session.hasFeatureEnabled(FEATURE.CUSTOM_MARKS) && type === POI_TYPE.CUSTOM) {
                  props.onClose();
                  return navigate(appRoutes.pricing);
                }

                if (type === POI_TYPE.WEATHER && props.weatherPoisCount >= 3) {
                  return notification.warning(t('poi:max_pois_created'));
                }

                props.poi.setType(model.type);
                props.poi.setName(t(props.poi.typeI18nKey));
              }}
            />
          </Grid>;
        })}
      </Grid>}
      {/* custom mark background selection */}
      {(props.poi.type === POI_TYPE.CUSTOM && !props.poi.customMark?.background) && <Grid container spacing={2}>
        {Object.values(CUSTOM_MARK_COLOR).map((color) => (
          <Grid item key={`custom-mark-color-${color}`} sm={12} md={6} xl={3}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => props.poi.customMark?.setBackground(color)}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              border={2}
              borderColor={'#D1D8E0'}
              borderRadius={'4px'}
              paddingY={2}
            >
              <PoiBackground
                color={color}
              />
            </Box>
          </Grid>
        ))}
      </Grid>}
      {/* custom mark foreground selection */}
      {(props.poi.type === POI_TYPE.CUSTOM && props.poi.customMark?.background && !props.poi.customMark.foreground) && <Grid container spacing={1} width={'100%'}>
        {customMarkIcons.map((icon: CUSTOM_MARK_ICON) => {
          return <Grid item key={`custommark-icon-${icon}`} sm={12} md={6} xl={4}>
            <PoiTypeButton
              sx={{ height: '100px' }}
              icon={getCustomMarkIcon(icon)}
              imgStyle={{ height: '25px', marginBottom: '10px' }}
              text={t(`poi:custom_poi_section.labels.${icon.toLowerCase()}`)}
              onClick={() => props.poi.customMark?.setForeground(icon)}
            />
          </Grid>;
        })}
      </Grid>}
      {/* poi features */}
      {(props.poi.typeReady && props.poi.type === POI_TYPE.CUSTOM) && <Grid item>
        <List sx={{ width: '100%' }}>
          <ListItem disablePadding>
            <ListItemButton onClick={props.poi.customMark?.features?.toggleTasks} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={props.poi.customMark?.features?.canHaveTasks}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={t('poi:tasks_feature_description')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={props.poi.customMark?.features?.toggleBookable} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={props.poi.customMark?.features?.isBookable}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={t('poi:bookings_feature_description')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={props.poi.customMark?.features?.toggleShootingDirection} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={props.poi.customMark?.features?.canHaveShootingDirection}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={t('poi:shooting_direction_description')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>}
    </Box>
  );
});
