import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { AreaPostRequestModel } from '../../domain/model/AreaPostRequestModel';
import { DistrictModel } from '../../domain/model/DistrictModel';
import { PoiModel } from '../../domain/model/PoiModel';
import { DistrictProxy } from '../../domain/proxy/DistrictProxy';
import { GeolocationService } from '../../domain/service/GeolocationService';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { transient } from '../../inversify/decorator';
import { Types } from '../../inversify/types';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { IMapBounds } from './components/google-map/GoogleMapVm';
import { MapVm } from './MapVm';

interface UserLocation {
  position: IGeoLocation;
  zoom: number;
}

@transient()
export class MapDistrictsVm extends ViewModel {

  // * for district view sidebar
  @observable
  public allDistricts: DistrictModel[] = [];

  // * for displaying on a map in a current bounds
  @observable
  public districtsInMapBounds: DistrictModel[] = [];

  @observable
  public selectedDistrict: DistrictModel | null = null;

  @observable
  public viewMode = false;

  @observable
  public userLocation: UserLocation | null = null;

  @observable
  public kmlImport: boolean = false;

  constructor(
    @inject(DistrictProxy) private readonly districtProxy: DistrictProxy,
    @inject(MapVm) public readonly mapVm: MapVm,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(GeolocationService) private readonly geolocationService: GeolocationService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
    @inject(Types.Params) private readonly params: { districtId: string | undefined },
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get setupModeActive() {
    return this.selectedDistrict?.setupActive ?? false;
  }

  @computed
  public get districtIds(): string[] {
    return this.allDistricts.map(district => district.id);
  }

  @action
  public setKmlImport = (kmlImport: boolean) => {
    this.kmlImport = kmlImport;
  }

  @action
  public removeDistrict = async (district: DistrictModel) => {
    this.allDistricts = this.allDistricts.filter((d) => d.id !== district.id);

    if (district.id === this.selectedDistrict?.id) {
      this.setPrivateMapAsDistrict();
    }

    this.deleteDistrictId(district.id);
  }

  @action
  public startNewDistrict = async (name: string, points: IGeoLocation[], kmlImport: boolean) => {
    const district = new DistrictModel();

    await this.tracking.track(TrackingEvent.DISTRICT_CREATION_STARTED);

    district.name = name;
    district.mesh.points = points;

    if (points.length) {
      if (district.mesh.isMeshClosed) {
        district.mesh.openMesh();
      }

      this.mapVm.centerMesh(district.mesh.points);
    }

    this.setKmlImport(kmlImport);
    this.setSelectedDistrict(district);
  }

  @action
  public cancelDistrictSetup = () => {
    if (this.selectedDistrict?.id) {
      const district = this.allDistricts.find((d) => d.id === this.selectedDistrict?.id);
      if (district) {
        this.selectDistrict(district);
      }
    } else {
      this.selectDistrict(this.allDistricts[0]);
    }
    this.setKmlImport(false);
  }

  @action
  public undoMeshPoint = () => {
    this.selectedDistrict?.mesh.undoLastMeshOperation();
  }

  // * fetching all districts, not just only the ones in a map viewport
  public getAllDistricts = new AsyncTask(async () => {
    try {
      this.setAllDistricts([]);
      const result = await this.districtProxy.getAllDistricts();
      if (result.ok) {
        return this.setAllDistricts(result.data.sort((a, b) => a.name.localeCompare(b.name)));
      }

      this.notification.error(this.i18n.t('map:districts_loading_error'));
    } catch (e) {
      console.error(`exception while loading districts. ${e}`);
      this.notification.error(this.i18n.t('map:districts_loading_error'));
    }
  })

  public getDistrictsInMapBounds = new AsyncTask(async (mapBounds: IMapBounds) => {
    try {
      this.setDistrictsInMapBounds([]);

      const dto = new AreaPostRequestModel().toDto(mapBounds);

      const result = await this.districtProxy.getDistrictsInMapBounds(dto);
      if (result.ok) {
        return this.setDistrictsInMapBounds(result.data);
      }

      console.warn(`error while loading districts. ${result.status}`);
      this.notification.warning(this.i18n.t('map:districts_loading_error'));
    } catch (e) {
      console.error(`exception while loading districts. ${e}`);
      this.notification.error(this.i18n.t('map:districts_loading_error'));
    }
  })

  public saveDistrict = new AsyncTask(async (pois: PoiModel[]) => {
    try {
      if (!this.selectedDistrict) {
        return;
      }

      const cloned = this.selectedDistrict.clone();
      cloned.mesh.closeMesh();

      const result = this.selectedDistrict?.id
        ? await this.districtProxy.updateDistrict(cloned.toPutDto())
        : await this.districtProxy.createDistrict(cloned.toPostDto(pois));

      if (result.ok) {
        await this.tracking.track(this.selectedDistrict?.id ? TrackingEvent.DISTRICT_UPDATE_COMPLETED : TrackingEvent.DISTRICT_CREATION_COMPLETED);

        this.upsert(result.data);
        this.setKmlImport(false);
        return this.selectDistrict(result.data);
      }

      this.notification.error(this.i18n.t('district:error_while_saving'));
    } catch (e) {
      console.error(`error while saving district. ${e}`);
      this.notification.error(this.i18n.t('district:error_while_saving'));
    }
  });

  @action
  public upsert = (district: DistrictModel) => {
    const index = this.allDistricts.findIndex((el) => el.id === district.id);

    if (index === -1) {
      this.allDistricts.unshift(district);
    } else {
      this.allDistricts.splice(index, 1, district);
    }
  }

  @action
  public setViewMode = (showing: boolean) => {
    this.viewMode = showing;
  }

  @action
  public setDistrictsInMapBounds = (districts: DistrictModel[]) => {
    this.districtsInMapBounds = districts;

    this.districtsInMapBounds.push(DistrictModel.privateMapDistrict(this.i18n.t('map:world_map')));
  }

  @action
  private setAllDistricts = (districts: DistrictModel[]) => {
    this.allDistricts = districts;

    const worldMap = DistrictModel.privateMapDistrict(this.i18n.t('map:world_map'));
    this.allDistricts.push(worldMap);

    if (this.allDistricts.length) {
      if (this.params.districtId) {
        const district = this.allDistricts.find((d) => d.id === this.params.districtId);
        if (district) {
          return this.selectDistrict(district);
        }
      }

      this.selectDistrict(this.allDistricts[0]);
    }
  }

  @action
  public setSelectedDistrict = (district: DistrictModel) => {
    this.selectedDistrict = district;

    if (this.selectedDistrict.isWorldMap) {
      this.navigate(DistrictModel.worldMapId);
    } else if (!this.selectedDistrict.id) {
      this.navigate('new');
    } else {
      this.navigate(this.selectedDistrict.id);
    }
  }

  @action
  public editDistrict = async (district: DistrictModel, points?: IGeoLocation[]) => {
    const clone = district.clone();

    await this.tracking.track(TrackingEvent.DISTRICT_UPDATE_STARTED);

    /** Update district borders with KML/GPX file.   */
    if (points) {
      await clone.mesh.setMesh(points);
    }

    clone.mesh.openMesh();
    this.selectDistrict(clone);
  }

  @action
  public deleteDistrict = async (district: DistrictModel) => {
    try {
      const result = await this.districtProxy.deleteDistrict(district.id);
      if (result.ok) {
        return runInAction(() => {
          this.removeDistrict(district);
        });
      }

      this.notification.error(this.i18n.t('district:delete_district_error'));
    } catch (e) {
      console.error(`exception while deleting district. ${e}`);
      this.notification.error(this.i18n.t('district:delete_district_error'));
    }
  }

  @action
  public deleteDistrictId = (id: string) => {
    this.allDistricts = this.allDistricts.filter((d) => d.id !== id);
  }

  @action
  public selectDistrict = async (district: DistrictModel) => {
    this.setSelectedDistrict(district);
    this.setKmlImport(false);

    if (this.selectedDistrict) {
      return this.mapVm.centerMesh(this.selectedDistrict?.mesh.points ?? []);
    }

    if (district.isWorldMap) {
      try {
        const location = await this.geolocationService.getCurrentLocation(true);
        this.mapVm.flyTo(location, 12);
      } catch {
        this.mapVm.flyTo(
          this.geolocationService.defaultLocation.location,
          this.geolocationService.defaultLocation.zoom
        );
      }
    }
  }

  /** Set Private Map when a user leaves or deletes the district. */
  private setPrivateMapAsDistrict = () => {
    this.selectDistrict(this.allDistricts.find(d => d.isWorldMap) ?? this.allDistricts[0]);
  }

  public zoomToDistrictLevel = () => {
    return this.mapVm.centerMesh(this.selectedDistrict?.mesh.points ?? []);
  }

}
