import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import {
  AnonymousSubscriptionPurchaseRoute
} from '../modules/auth/anonymous-subscription-purchase/AnonymousSubscriptionPurchaseRoute';
import { AnonymousRoute } from '../modules/auth/AnonymousRoute';
import { ForgotPassword } from '../modules/auth/forgot-password/ForgotPassword';
import { LoginRoute } from '../modules/auth/login/LoginRoute';
import { NewPassword } from '../modules/auth/new-password/NewPassword';
import {
  EmailRegistrationRoute
} from '../modules/auth/register/components/email-registration/EmailRegistrationRoute';
import {
  SocialMediaRegistrationRoute
} from '../modules/auth/register/components/social-media-registration/SocialMediaRegistrationRoute';
import { RegistrationRoute } from '../modules/auth/register/RegistrationRoute';
import { DownloadAppRoute } from '../modules/download-app/DownloadAppRoute';
import { ImageGalleryRoute } from '../modules/image-gallery/ImageGalleryRoute';
import { MapRoute } from '../modules/map/MapRoute';
import {
  BuyGroupSubscription
} from '../modules/pricing/buy-group-subscription/BuyGroupSubscription';
import {
  SuccessfulPaymentEmbeddedRoute
} from '../modules/pricing/components/successful-payment/SuccessfulPaymentEmbedded';
import {
  SuccessfulPaymentRoute
} from '../modules/pricing/components/successful-payment/SuccessfulPaymentRoute';
import { GroupDiscount } from '../modules/pricing/group-discount/GroupDiscount';
import { PricingRoute } from '../modules/pricing/PricingRoute';
import {
  AddMemberRoute
} from '../modules/user-profile/components/manage-group/add-member/AddMemberRoute';
import { ManageGroupRoute } from '../modules/user-profile/components/manage-group/ManageGroupRoute';
import { UserProfileRoute } from '../modules/user-profile/UserProfileRoute';
import { Analytics } from './Analytics';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { appRoutes } from './routes';

export function Router() {

  return (
    <BrowserRouter>
      <Analytics>
        <Routes>
          <Route path={appRoutes.login} element={
            <AnonymousRoute>
              <LoginRoute />
            </AnonymousRoute>}
          />
          <Route path={appRoutes.register} element={
            <AnonymousRoute>
              <RegistrationRoute />
            </AnonymousRoute>}
          />
          <Route path={appRoutes.emailRegister} element={
            <AnonymousRoute>
              <EmailRegistrationRoute />
            </AnonymousRoute>}
          />
          <Route path={appRoutes.socialMediaRegister} element={
            <AnonymousRoute>
              <SocialMediaRegistrationRoute />
            </AnonymousRoute>}
          />
          <Route path={appRoutes.forgotPassword} element={
            <AnonymousRoute>
              <ForgotPassword />
            </AnonymousRoute>}
          />
          <Route path={appRoutes.newPassword} element={
            <AnonymousRoute>
              <NewPassword />
            </AnonymousRoute>}
          />
          <Route path={appRoutes.subscriptionPurchase} element={<AnonymousSubscriptionPurchaseRoute />} />

          <Route path={appRoutes.downloadAppRoute} element={
            <AuthenticatedRoute>
              <DownloadAppRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.userProfile} element={
            <AuthenticatedRoute>
              <UserProfileRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.pricing} element={
            <AuthenticatedRoute>
              <PricingRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.pricingEmbedded} element={
            <AuthenticatedRoute>
              <PricingRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.groupDiscount} element={
            <AuthenticatedRoute>
              <GroupDiscount />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.buyGroupSubscription} element={
            <AuthenticatedRoute>
              <BuyGroupSubscription />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.successfulPayment} element={
            <AuthenticatedRoute>
              <SuccessfulPaymentRoute />
            </AuthenticatedRoute>
          } />
          <Route path={appRoutes.successfulPaymentUnauthorized} element={<SuccessfulPaymentRoute />} />
          <Route path={appRoutes.successfulPaymentEmbedded} element={
            <AuthenticatedRoute>
              <SuccessfulPaymentEmbeddedRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.map} element={
            <AuthenticatedRoute>
              <MapRoute />
            </AuthenticatedRoute>}
          >
            <Route path=":districtId" element={null} />
          </Route>
          <Route path={appRoutes.gallery} element={
            <AuthenticatedRoute>
              <ImageGalleryRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.manageGroup} element={
            <AuthenticatedRoute>
              <ManageGroupRoute />
            </AuthenticatedRoute>}
          />
          <Route path={appRoutes.groupMemberAdder} element={
            <AuthenticatedRoute>
              <AddMemberRoute />
            </AuthenticatedRoute>}
          />

          <Route path="*" element={
            <AuthenticatedRoute>
              <Navigate to={appRoutes.map} />
            </AuthenticatedRoute>}
          />
        </Routes>
      </Analytics>
    </BrowserRouter>
  );
}
