import { endOfDay, isAfter, isEqual, startOfDay, sub } from 'date-fns';
import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { QuotaPlanModel } from '../../../../../../../domain/model/QuotaPlanModel';
import { HarvestTagProxy } from '../../../../../../../domain/proxy/HarvestTagProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { TrackingService } from '../../../../../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { env } from '../../../../../../../env';
import { transient } from '../../../../../../../inversify/decorator';
import { Types } from '../../../../../../../inversify/types';
import { appRoutes } from '../../../../../../../router/routes';
import { HarvestTagResponseDto } from '../../../../../../../shared/dto/harvestTag.response.dto';
import { FEATURE } from '../../../../../../../shared/enum';
import { QuotaPlanStatus } from '../../../../../../../shared/enum/quotaPlanStatus.enum';
import {
  ClassificationHelper
} from '../../../../../../../util/classification/ClassificationHelper';

export interface ViewDistrictHarvestListProps {
  districtId: string;
}

@transient()
export class ViewDistrictHarvestListVm extends ViewModel<ViewDistrictHarvestListProps> {

  public currentDate = new Date();

  @observable
  public from: Date = this.defaultStartDate;

  @observable
  public to: Date = this.defaultEndDate;

  @observable
  public harvestList: HarvestTagResponseDto[] = [];

  @observable
  public harvestQuotaList: QuotaPlanModel[] = [];

  @observable
  public showActiveHarvestQuota: boolean = false;

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(HarvestTagProxy) private readonly harvestTagProxy: HarvestTagProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(ClassificationHelper) public readonly classificationHelper: ClassificationHelper,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
    @inject(TrackingService) public readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    this.getHarvestList.run();
    this.getHarvestQuotas.run();
  }

  @computed
  public get hasDateChanged() {
    return !isEqual(this.from, this.defaultStartDate) || !isEqual(this.to, this.defaultEndDate);
  }

  public get defaultStartDate(): Date {
    const now = new Date(this.currentDate);

    // 1st of April
    const startOfHuntingSeason = new Date(this.currentDate.getFullYear(), 3, 1);

    if (isAfter(startOfHuntingSeason, now)) {
      return sub(startOfHuntingSeason, {
        years: 1
      });
    }

    return startOfDay(startOfHuntingSeason);
  }

  public get defaultEndDate(): Date {
    return endOfDay(new Date(this.currentDate));
  }

  @computed
  public get hasProHarvestList() {
    return this.session.hasFeatureEnabled(FEATURE.HARVEST_TAG_EXPORT);
  }

  @computed
  public get shouldDisableQuotaSwitch(): boolean {
    return this.harvestQuotaList.length === 0 || !this.activeQuotaPlan;
  }

  @action
  public resetDates = () => {
    this.from = this.defaultStartDate;
    this.to = this.defaultEndDate;
    this.getHarvestList.run();
  }

  @action
  public setFrom = (from: Date | null) => {
    if (!from) {
      return;
    }

    this.from = startOfDay(from);
    this.getHarvestList.run();
  }

  @action
  public setTo = (to: Date | null) => {
    if (!to) {
      return;
    }

    this.to = endOfDay(to);
    this.getHarvestList.run();
  }

  @action
  public toggleShowHarvestQuota = () => {
    this.showActiveHarvestQuota = !this.showActiveHarvestQuota;
  }

  public downloadHarvestList = () => {
    const url = `${env.api}/harvest-list/download?token=${this.session.session?.sessionToken}&districtId=${this.props.districtId}&startDate=${this.from.toISOString()}&endDate=${this.to.toISOString()}&language=${this.i18n.language}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop() ?? '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public showProPage = () => {
    this.navigate(appRoutes.pricing);
  }

  public onDownloadClick = () => {
    if (this.hasProHarvestList) {
      return this.downloadHarvestList();
    }

    this.showProPage();
  }

  @computed
  public get isBusy(): boolean {
    return this.getHarvestList.isBusy || this.getHarvestQuotas.isBusy;
  }

  @computed
  public get activeQuotaPlan(): QuotaPlanModel | undefined {
    if (this.harvestQuotaList.length) {
      return this.harvestQuotaList.find(ql => ql.status === QuotaPlanStatus.ACTIVE);
    }
  }

  public getHarvestList = new AsyncTask(async () => {
    try {
      const result = await this.harvestTagProxy.getHarvestList({
        districtId: this.props.districtId,
        startDate: this.from.toISOString(),
        endDate: this.to.toISOString(),
        language: this.i18n.language,
      });

      if (result.ok) {
        return runInAction(() => {
          this.harvestList = result.data;
        });
      }

      this.notification.error(this.i18n.t('district:harvest_filter_fetching_error'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('district:harvest_filter_fetching_error'));
    }
  });

  public getHarvestQuotas = new AsyncTask(async () => {
    try {
      const result = await this.harvestTagProxy.getDistrictQuotaPlans(this.props.districtId);

      if (result.ok) {
        const harvestQuotas = result.data.map(r => QuotaPlanModel.fromDto(r));
        return runInAction(() => {
          this.harvestQuotaList = harvestQuotas;
        });
      }

      this.notification.error(this.i18n.t('district:harvest_filter_fetching_error'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('district:harvest_filter_fetching_error'));
    }
  })
}
