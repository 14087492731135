import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import * as queryString from 'query-string';
import { NavigateFunction } from 'react-router-dom';

import { ViewModel } from '../../../domain/core/ViewModel';
import { AuthProxy } from '../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { transient } from '../../../inversify/decorator';
import { Types } from '../../../inversify/types';
import { appRoutes } from '../../../router/routes';
import { UserPatchResetPasswordDto } from '../../../shared/dto';


@transient()
export class NewPasswordVm extends ViewModel {

  @observable
  public password: string = '';

  @observable
  public repeatedPassword: string = '';

  @observable
  public passwordShown: boolean = false;

  constructor(
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get queryLanguage(): string {
    const { lang } = queryString.parse(window.location.search);
    return lang as string;
  }

  @computed
  public get queryToken(): string {
    const { token } = queryString.parse(window.location.search);
    return token as string;
  }

  @action
  public setPassword = (password: string): void => {
    this.password = password;
  }

  @action
  public setRepeatedPassword = (repeatedPassword: string): void => {
    this.repeatedPassword = repeatedPassword;
  }

  @action
  public setShowPassword = (passwordShown: boolean) => {
    this.passwordShown = passwordShown;
  }

  public onSubmit = async (tokenId: string, password: UserPatchResetPasswordDto): Promise<void> => {
    const result = await this.authProxy.resetPassword.run(tokenId, password);

    if (!result.ok) {
      this.notification.error(this.i18n.t('auth:reset_step3_error'));
      return;
    }

    this.notification.success(this.i18n.t('auth:reset_success'));
    this.navigate(appRoutes.login);
  }

  @computed
  public get isBusy() {
    return this.authProxy.resetPassword.isBusy;
  }
}
