import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce, useKeyPress } from 'react-use';

import { Box } from '@mui/system';

import { Assets } from '../../../../assets';
import { useInstance } from '../../../../domain/hooks/useInstance';
import { DistrictModel } from '../../../../domain/model/DistrictModel';
import { EntryModel } from '../../../../domain/model/EntryModel';
import { MapPathModel } from '../../../../domain/model/MapPathModel';
import { MeshModel } from '../../../../domain/model/MeshModel';
import { PoiModel } from '../../../../domain/model/PoiModel';
import { SubzoneModel } from '../../../../domain/model/SubzoneModel';
import { GeolocationService } from '../../../../domain/service/GeolocationService';
import { env } from '../../../../env';
import { ENTRY_TYPE, POI_TYPE } from '../../../../shared/enum';
import { IGeoLocation } from '../../../../shared/interfaces/IGeoLocation';
import { GeoUtil } from '../../../../util/GeoUtil';
import { MapType } from '../../MapVm';
import { PinSizeContext } from '../../PinSizeContext';
import { DistrictCircle } from '../DistrictCircle';
import { EntryMarker } from '../entry/marker/EntryMarker';
import { MapPathMarker } from '../map-path/MapPathMarker';
import { MapPathPoint } from '../map-path/MapPathPoint';
import { PoiMarker } from '../poi/marker/PoiMarker';
import { PoiWeatherMarker } from '../poi/weather-marker/PoiWeatherMarker';
import { SubzoneMarker } from '../subzone/SubzoneMarker';
import { SubzoneCircle } from '../SubzoneCircle';
import { GoogleMapDistrictLabel } from './GoogleMapDistrictLabel';
import { GoogleMapVm } from './GoogleMapVm';
import { useDrawDistrictMesh } from './hooks/useDrawDistrictMesh';
import { useDrawDistrictsMesh } from './hooks/useDrawDistrictsMesh';
import { useDrawMapPaths } from './hooks/useDrawMapPaths';
import { useDrawSubzones } from './hooks/useDrawSubzones';
import { useMapClick } from './hooks/useMapClick';
import { createUUID } from '../../../../__test__/uuid';

interface IProps {
  selectedDistrict: DistrictModel | null;
  districts: DistrictModel[];
  onDistrictLabelClick: (district: DistrictModel) => void
  entries: EntryModel[];
  pois: PoiModel[];
  kmlImport: boolean;
  mapPaths: MapPathModel[];
  subzones: SubzoneModel[];
  vm: GoogleMapVm;
  children: React.ReactNode;
  currentMapPath: MapPathModel | null;
  onDragStart: () => void;
  onDragEnd: () => void;
  onEntryClick: (entry: EntryModel) => void;
  onPoiClick: (poi: PoiModel) => void;
  onSubzoneClick: (subzone: SubzoneModel) => void;
  onMapPathClick: (mapPath: MapPathModel) => void;
  onMapClick: (location: IGeoLocation) => void;
  onDistrictLineClick: (location: IGeoLocation) => void;
  showMapPathsTooltip: boolean;
  highlightedId: string | undefined;
  draggableCursor?: string;
  subzoneTmpCircle: IGeoLocation | null;
  setSubzoneTmpCircle: (location: IGeoLocation | null) => void;
  mapType: MapType;
  showPoiLabels: boolean;
  isPrintMapActive: boolean;
}

function getPointIndex(key: string | undefined): number | undefined {
  if (!key) {
    return;
  }

  let index = key.match(/^(district|path)-point-(?<index>\d+)$/)?.groups?.index;
  if (index != null) {
    return parseInt(index);
  }

  index = key.match(/^subzone-(?<sindex>\d+)-point-(?<pindex>\d+)$/)?.groups?.pindex;
  if (index != null) {
    return parseInt(index);
  }
}

export const GoogleMap = observer(function GoogleMap(props: IProps) {
  const [movingPin, setMovingPin] = useState(false);
  const geolocationService = useInstance(GeolocationService);
  const [backspacePressed] = useKeyPress(useCallback((event: KeyboardEvent) => event.key === 'Backspace', []));
  const pinSizeContext = useContext(PinSizeContext);
  const vm = props.vm;
  const map = vm.map;
  const mesh = useMemo(() => props.selectedDistrict?.mesh ?? new MeshModel([]), [props.selectedDistrict?.mesh]);
  const subzoneSetupInProgress = props.subzones.find((s) => !s.mesh.isMeshClosed);
  const showElements = useMemo(() => Boolean(pinSizeContext.zoomLevel && pinSizeContext.zoomLevel >= 13.5), [pinSizeContext.zoomLevel]);

  const showEntriesNoMatterZoomLevel = useMemo(() => (props.entries ?? []).filter(entry => entry.districtId === props.selectedDistrict?.id), [props.entries, props.selectedDistrict?.id]);
  const showPoisNoMatterZoomLevel = useMemo(() => (props.pois ?? []).filter(poi => poi.districtId === props.selectedDistrict?.id), [props.pois, props.selectedDistrict?.id]);
  const showMapPathsNoMatterZoomLevel = useMemo(() => (props.mapPaths ?? []).filter(mapPath => mapPath.districtId === props.selectedDistrict?.id), [props.mapPaths, props.selectedDistrict?.id]);
  const showSubzonesNoMatterZoomLevel = useMemo(() => (props.subzones ?? []).filter(subzone => subzone.districtId === props.selectedDistrict?.id), [props.subzones, props.selectedDistrict?.id]);

  const mapsRef = useRef<typeof google.maps | null>();

  const renderMapDistrictLabels = useMemo(() => {
    return !showElements && props.districts.filter(d => d.mesh.points.length > 1 && d.id !== props.selectedDistrict?.id).map(d => {
      const centerPoint = GeoUtil.extractCenterForMesh(d.mesh);
      return (
        !props.isPrintMapActive && <GoogleMapDistrictLabel
          key={`google-district-label-marker-${d.id}`}
          name={d.name}
          lat={centerPoint.latitude}
          lng={centerPoint.longitude}
          onClick={() => props.onDistrictLabelClick(d)}
        />
      );
    });
  }, [props, showElements]);

  const defaultProps = useMemo(() => {
    return {
      center: {
        lat: geolocationService.defaultLocation.location.latitude,
        lng: geolocationService.defaultLocation.location.longitude,
      },
      zoom: geolocationService.defaultLocation.zoom,
    };
  }, [geolocationService]);

  const googleMapType = useMemo(() => {
    if (props.mapType === 'satellite') {
      return google.maps.MapTypeId.HYBRID;
    }

    if (props.mapType === 'terrain') {
      return google.maps.MapTypeId.TERRAIN;
    }

    if (props.mapType === 'roadmap') {
      return google.maps.MapTypeId.ROADMAP;
    }

    return google.maps.MapTypeId.HYBRID;
  }, [props.mapType]);

  const onMapReady = useCallback((maps: { map: google.maps.Map, maps: typeof google.maps }) => {
    vm.setMap(maps.map);

    if (props.selectedDistrict?.mesh.points) {
      vm.centerMesh(props.selectedDistrict?.mesh.points);
    }

    mapsRef.current = maps.maps;

  }, [vm, props.selectedDistrict?.mesh.points]);

  useEffect(() => {
    let mouseMoveListener: google.maps.MapsEventListener | null = null;
    let zoomLevelListener: google.maps.MapsEventListener | null = null;
    let dragStartListener: google.maps.MapsEventListener | null = null;
    let dragEndListener: google.maps.MapsEventListener | null = null;
    let idleListener: google.maps.MapsEventListener | null = null;

    if (map) {
      map.setOptions({ isFractionalZoomEnabled: true });

      mouseMoveListener = google.maps.event.addListener(map, 'mousemove', function (event: { latLng: google.maps.LatLng }) {
        vm.handleMouseMove({ latitude: event.latLng.lat(), longitude: event.latLng.lng() });
      });

      zoomLevelListener = google.maps.event.addListener(map, 'zoom_changed', function () {
        vm.handleZoomChange(map.getZoom());
      });

      dragStartListener = google.maps.event.addListener(map, 'dragstart', function () {
        props.onDragStart();
      });

      dragEndListener = google.maps.event.addListener(map, 'dragend', function () {
        props.onDragEnd();
      });

      idleListener = google.maps.event.addListener(map, 'idle', function () {
        if (!mapsRef.current || !mapsRef.current.geometry) return;
        vm.calculateBounds(mapsRef.current);
      });
    }

    return () => {
      if (mouseMoveListener) {
        google.maps.event.removeListener(mouseMoveListener);
      }

      if (zoomLevelListener) {
        google.maps.event.removeListener(zoomLevelListener);
      }

      if (dragStartListener) {
        google.maps.event.removeListener(dragStartListener);
      }

      if (dragEndListener) {
        google.maps.event.removeListener(dragEndListener);
      }

      if (idleListener) {
        google.maps.event.removeListener(idleListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useDebounce(() => pinSizeContext.setZoomLevel(vm.lastZoomLevel), 600, [vm.lastZoomLevel]);

  // * draw other districts than selected
  useDrawDistrictsMesh({
    map,
    mapType: googleMapType,
    selectedDistrict: props.selectedDistrict,
    districts: props.districts,
    strokeWeight: subzoneSetupInProgress ? 5 : undefined,
    onLineHover: props.setSubzoneTmpCircle,
    onLineClick: props.onDistrictLineClick,
  });

  // * draw selected district
  useDrawDistrictMesh({
    map,
    mesh,
    meshClosed: props.selectedDistrict?.mesh.isMeshClosed ?? false,
    strokeWeight: subzoneSetupInProgress ? 5 : undefined,
    onLineHover: props.setSubzoneTmpCircle,
    onLineClick: props.onDistrictLineClick,
  });

  useDrawMapPaths({
    map,
    mapPaths: showElements ? props.mapPaths : showMapPathsNoMatterZoomLevel,
    enabled: vm.hasProPathsEnabled,
    highlightedId: props.highlightedId,
    currentMapPath: props.currentMapPath,
  });

  useDrawSubzones(vm.hasProSubzones, showElements ? props.subzones : showSubzonesNoMatterZoomLevel, map);

  // get mesh which is currently being in setup
  // this can be district mesh or path mesh
  const getCurrentMeshInSetup = useCallback(() => {
    const districtSetupActive = !props.selectedDistrict?.mesh.isMeshClosed;
    const mapPathInSetup = props.mapPaths.find((p) => p.id === props.currentMapPath?.id);
    const subzoneInSetup = props.subzones.find((s) => !s.mesh.isMeshClosed);

    // if currently district or map path setup is not active
    if (!districtSetupActive && !mapPathInSetup && !subzoneInSetup) {
      return;
    }

    // mesh which we are editing right now
    return districtSetupActive
      ? props.selectedDistrict?.mesh : mapPathInSetup
        ? mapPathInSetup.mesh
        : subzoneInSetup
          ? subzoneInSetup.mesh
          : null;
  }, [props.selectedDistrict, props.mapPaths, props.subzones, props.currentMapPath]);

  const onMouseMove = useCallback((key, _, mouse) => {
    const targetMesh = getCurrentMeshInSetup();

    // index of point in the mesh which we are editing right now
    const index = getPointIndex(key);

    // update point location
    if (targetMesh && index != null && mouse.lat && mouse.lng) {
      targetMesh.updatePointLocation(
        index,
        { latitude: mouse.lat, longitude: mouse.lng },
        !movingPin, // capture only first move event
      );
    }

    setMovingPin(true);
  }, [getCurrentMeshInSetup, movingPin]);

  const onMouseRelease = useCallback(() => {
    setMovingPin(false);
  }, []);

  const onDistrictMeshPointClick = useCallback((location: IGeoLocation) => {
    props.selectedDistrict?.mesh.selectMeshPoint(location);
  }, [props.selectedDistrict?.mesh]);

  const onSubzoneMeshPointClick = useCallback((location: IGeoLocation) => {
    const wipSubzone = props.subzones.find((s) => !s.mesh.isMeshClosed);
    if (wipSubzone) {
      wipSubzone.mesh.selectMeshPoint(location);
    }
  }, [props.subzones]);

  const removeDistrictMeshPoint = useCallback((point: IGeoLocation) => {
    props.selectedDistrict?.mesh.removeMeshPoint(point);
  }, [props.selectedDistrict?.mesh]);

  const renderedEntries = useMemo(() => {
    const entriesToRender = (showElements && (props.entries ?? []) || showEntriesNoMatterZoomLevel).filter((e) => !!e.location);

    return entriesToRender.map((entry: EntryModel) => {
      const isEntryInSelectedDistrict = entry.districtId === props.selectedDistrict?.id;
      const shouldStillBeNormalOpacity =
        entry.districtId !== props.selectedDistrict?.id &&
        entry.entryType === ENTRY_TYPE.KILLING &&
        entry.owner?.id === vm.session.currentUser?.id &&
        !props.districts.some(district => district.id === entry.districtId);

      const isEntryHighlighted =
        props.highlightedId != null && props.highlightedId === entry.id;

      const opacity =
        props.highlightedId == null
          ? isEntryInSelectedDistrict || shouldStillBeNormalOpacity
            ? 1
            : 0.5
          : isEntryHighlighted
            ? 1
            : 0.5;

      return (
        <EntryMarker
          tooltipEnabled={!subzoneSetupInProgress}
          onClick={props.onEntryClick}
          key={`entry-point-${entry.id}`}
          entry={entry}
          opacity={opacity}
          lat={entry.location?.latitude}
          lng={entry.location?.longitude}
        />
      );
    });
  }, [showElements, props.entries, props.selectedDistrict?.id, props.districts, props.highlightedId, props.onEntryClick, showEntriesNoMatterZoomLevel, vm.session.currentUser?.id, subzoneSetupInProgress]);

  useEffect(() => {
    if (backspacePressed) {
      getCurrentMeshInSetup()?.removeSelection();
    }
  }, [backspacePressed, getCurrentMeshInSetup]);

  useMapClick(map, props.onMapClick);

  return (
    <Box
      position={'relative'}
      width={'100%'}
      height={'100%'}
      overflow={'hidden'}
    >
      <Box height={'inherit'} id="map-wrapper">
        <GoogleMapReact
          bootstrapURLKeys={{ key: env.google.mapApiKey, language: vm.language, libraries: ['geometry'] }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={onMapReady}
          options={{
            keyboardShortcuts: false,
            disableDefaultUI: true,
            scaleControl: true,
            tilt: 0,
            draggableCursor: props.draggableCursor,
            fullscreenControl: false,
            rotateControl: false,
            mapTypeControlOptions: {
            },
            mapTypeId: googleMapType
          }}
          onChildMouseMove={onMouseMove}
          onChildMouseUp={onMouseRelease}
        >
          {/* rendering district name labels above certain zoom level */}
          {renderMapDistrictLabels}

          {/* rendering current district circles */}
          {!mesh.isMeshClosed && mesh.points.map((point, index) => <DistrictCircle
            // getPointIndex fn needs to be updated if key is changed
            key={`district-point-${index}`}
            onClick={onDistrictMeshPointClick}
            onDelete={removeDistrictMeshPoint}
            lat={point.latitude}
            lng={point.longitude}
            selected={mesh.selectedMeshPoint?.latitude === point?.latitude && mesh.selectedMeshPoint?.longitude === point?.longitude}
          />)}

          {/* subzone circles */}
          {vm.hasProSubzones && props.subzones.filter(s => !s.mesh.isMeshClosed).map((subzone, sindex) => {
            return subzone.mesh.points.map((point, pindex) => <SubzoneCircle
              // getPointIndex fn needs to be updated if key is changed
              key={`subzone-${sindex}-point-${pindex}`}
              onClick={onSubzoneMeshPointClick}
              onDelete={subzone.mesh.removeMeshPoint}
              lat={point.latitude}
              lng={point.longitude}
              setupInProgress={!subzone.mesh.isMeshClosed ?? true}
              color={subzone.color}
              selected={subzone.mesh.selectedMeshPoint?.latitude === point?.latitude &&
                subzone.mesh.selectedMeshPoint?.longitude === point?.longitude
              }
              zIndex={3}
            />);
          })}
          {(vm.hasProSubzones && subzoneSetupInProgress && props.subzoneTmpCircle) && <SubzoneCircle
            key={'subzone-tmp-circle'}
            // getPointIndex fn needs to be updated if key is changed
            lat={props.subzoneTmpCircle.latitude}
            lng={props.subzoneTmpCircle.longitude}
            color={subzoneSetupInProgress.color}
            selected={false}
            setupInProgress={false}
            onClick={() => {/** */ }}
            onDelete={() => {/** */ }}
            zIndex={1}
          />}

          {/* subzone center markers */}
          {(showElements && props.subzones || showSubzonesNoMatterZoomLevel)
            .filter(s => s.pinLocation)
            .map((subzone) => <SubzoneMarker
              key={`subzone-${subzone.id}-marker`}
              lat={subzone.pinLocation?.latitude}
              lng={subzone.pinLocation?.longitude}
              icon={vm.hasProSubzones ? subzone.icon : Assets.poi.custom_pro}
              onClick={() => props.onSubzoneClick(subzone)}
              opacity={props.highlightedId == null
                ? subzone.districtId === props.selectedDistrict?.id ? 1 : 0.5
                : props.highlightedId === subzone.id ? 1 : 0.5
              }
            />)}

          {/* entries */}
          {renderedEntries}

          {/* rendering map path points for pro users */}
          {vm.hasProPathsEnabled && (showElements && props.mapPaths || showMapPathsNoMatterZoomLevel).map((path) => {
            if (!path.mesh.points.length) {
              return null;
            }
            return path.mesh.points.map((point, index) => {
              const setupInProgress = props.currentMapPath?.id === path.id;
              const start = index === 0;
              const end = index === path.mesh.points.length - 1;
              if (!start && !end && !setupInProgress) {
                return;
              }
              return (
                <MapPathPoint
                  // getPointIndex fn needs to be updated if key is changed
                  key={`path-point-${index}`}
                  lat={point.latitude}
                  lng={point.longitude}
                  mapPath={path}
                  start={start}
                  end={end}
                  opacity={props.highlightedId == null
                    ? path.districtId === props.selectedDistrict?.id ? 1 : 0.5
                    : props.highlightedId === path.id ? 1 : 0.5
                  }
                  onClick={props.onMapPathClick}
                  showUtilsTooltip={props.showMapPathsTooltip}
                  showInfoTooltip={!subzoneSetupInProgress}
                  setupInProgress={setupInProgress}
                />
              );
            });
          })}
          {/* rendering map path points for non-pro users */}
          {!vm.hasProPathsEnabled && (showElements && props.mapPaths || showMapPathsNoMatterZoomLevel).map((path) => {
            if (!path.mesh.points.length) {
              return null;
            }
            return <MapPathMarker
              key={`path-${path.id}`}
              lat={path.mesh.points[0].latitude}
              lng={path.mesh.points[0].longitude}
              icon={Assets.path.proPath}
              opacity={props.highlightedId == null
                ? path.districtId === props.selectedDistrict?.id ? 1 : 0.5
                : props.highlightedId === path.id ? 1 : 0.5
              }
              onClick={() => props.onMapPathClick(path)}
            />;
          })}

          {/* rendering pois */}
          {(showElements && props.pois || showPoisNoMatterZoomLevel).filter((p) => !!p.location && p.type !== POI_TYPE.WEATHER)?.map((poi) => {
            return <PoiMarker
              key={`map-poi-${poi.id ? poi.id : createUUID()}`}
              onClick={props.onPoiClick}
              lat={poi.location?.latitude}
              lng={poi.location?.longitude}
              poi={poi}
              opacity={props.highlightedId == null
                ? props.kmlImport || poi.districtId === props.selectedDistrict?.id ? 1 : 0.5
                : props.highlightedId === poi.id ? 1 : 0.5
              }
              showPoiLabels={props.showPoiLabels}
              hideTooltip={!!subzoneSetupInProgress}
              hideShootingDirection={props.highlightedId !== poi.id}
            />;
          })}
          {/* rendering weather pois */}
          {(showElements && props.pois || showPoisNoMatterZoomLevel).filter((p) => p.type === POI_TYPE.WEATHER)?.map((poi) => <PoiWeatherMarker
            key={`map-weather-poi-${poi.id}`}
            lat={poi.location?.latitude}
            lng={poi.location?.longitude}
            poi={poi}
            opacity={props.highlightedId == null
              ? poi.districtId === props.selectedDistrict?.id ? 1 : 0.5
              : props.highlightedId === poi.id ? 1 : 0.5
            }
          />)}
        </GoogleMapReact>
      </Box>
      {props.children}
    </Box>
  );
});
