export const appRoutes = {
  downloadAppRoute: '/download',
  userProfile: '/profile',
  pricing: '/pricing',
  pricingEmbedded: '/pricing-embedded',
  groupDiscount: '/pricing/group-discount',
  buyGroupSubscription: '/pricing/group-discount/buy-group-subscription',
  canceledPayment: '/canceled-payment',
  successfulPayment: '/successful-payment',
  successfulPaymentUnauthorized: '/successful-payment-unauthorized',
  successfulPaymentEmbedded: '/successful-payment-embedded',
  login: '/auth/login',
  register: '/auth/register',
  emailRegister: '/auth/email-register',
  socialMediaRegister: '/auth/social-media-register',
  forgotPassword: '/auth/forgot-password',
  newPassword: '/auth/new-password',
  subscriptionPurchase: '/auth/subscription-purchase',
  map: '/map/districts',
  gallery: '/image-gallery',
  manageGroup: '/profile/manage-group',
  groupMemberAdder: '/profile/manage-group/add-member',
};
