import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { StripeProductModel } from '../../../domain/model/stripe/StripeProductModel';
import { RevenueCatProxy } from '../../../domain/proxy/RevenueCatProxy';
import { StripeProxy } from '../../../domain/proxy/StripeProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { env } from '../../../env';
import { transient } from '../../../inversify/decorator';
import {
  SubscriptionAnonymousPostRequestDto
} from '../../../shared/dto/subscription/subscription.anonymous.post.request.dto';
import { PurchasesOffering, PurchasesPackage } from '../../../typings/revenue-cat';

@transient()
export class AnonymousSubscriptionPurchaseRouteVm extends ViewModel {

  @observable
  public products: StripeProductModel[] = [];

  @observable
  public userId: string = '';

  @observable
  public email: string = '';

  constructor(
    @inject(RevenueCatProxy) private readonly revenueCatProxy: RevenueCatProxy,
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isLoading(): boolean {
    return this.getOfferings.isBusy || this.getProducts.isBusy;
  }

  @action
  private setProducts = (products: StripeProductModel[]): void => {
    this.products = products;
  }

  @action
  public setUserId = (userId: string) => {
    this.userId = userId;
  }

  @action
  public setEmail = (email: string) => {
    this.email = email;
  }

  /**
   *  Works only if fullname is exactly like this: "Monthly some other_words"
   *  the first word in fullname is ${name} part of locize key. So, eg:
   *    fullname => "Monthly some other_words"
   *    first word, aka ${name} => "monthly"
   *    locize key => payment:subscription_monthly
   */
  @action
  public getTranslation = (fullname: string) => {
    const name = fullname.toLowerCase().split(' ')[0];
    return this.i18n.t(`payment:subscription_${name}`);
  };

  public getOfferings = new AsyncTask(async (): Promise<string[] | undefined> => {
    try {
      const result = await this.revenueCatProxy.getOfferings();

      if (!result.ok || !result.data) {
        this.notification.error(this.i18n.t('payment:errors.offerings_warning'));
        return;
      }

      if (result.ok && result.data) {
        const { offerings } = result.data;
        const defaultOffering = offerings.find(
          (offering: PurchasesOffering) => offering.identifier === env.revenueCat.offerings
        ) || null;
        const productIds: string[] = defaultOffering?.packages.map((purchasePackage: PurchasesPackage) => purchasePackage.platform_product_identifier) || [];

        return productIds;
      }
    }
    catch (error) {
      this.notification.error(this.i18n.t('payment:errors.offerings_error'));
    }
  })

  public getProducts = new AsyncTask(async (productsIds: string[] = []): Promise<void> => {
    try {
      /**
       * The code runs "getProducts({})", which fetches all products from Stripe. We then compare the "product.metadata.language === i18n.language".
       * The RevenueCat offerings only allow one product ID per package identifier per platform:
       *    offerings -> {offering_identifier} -> {package_identifier} -> {platform}
       *        {package_identifier}: monthly, three month, annual, group
       *        {platform}: App Store, Play Store, Stripe
       * RevenueCat only returns one Stripe product per platform, which is usually DE. To handle this limitation, we fetch all products from Stripe and
       * look for the ones where "product.metadata.language === i18n.language". Therefore, every Stripe product must have a "metadata.language" field.
      */
      const allProducts = await this.stripeProxy.getProducts({ userId: this.userId });

      if (!allProducts.ok || !allProducts.data) {
        this.notification.error(this.i18n.t('payment:errors.products_warning'));
        return;
      }

      const singleUserProducts = allProducts.data.filter((product) => !product.tiered);
      const revenueCatProducts = singleUserProducts.filter(product => productsIds.includes(product.id));
      const sortedByPrice = revenueCatProducts.sort((a, b) => a.price!.priceAmount - b.price!.priceAmount);

      const arrayWithPlusSubscriptionAtTheEnd = sortedByPrice.sort((a, b) => {
        if (a.name.includes('PLUS')) {
          return 1; // Move "PLUS" to the end
        } else if (b.name.includes('PLUS')) {
          return -1; // Keep "PLUS" at the end
        } else {
          return 0; // Maintain the relative order of other elements
        }
      });

      this.setProducts(arrayWithPlusSubscriptionAtTheEnd);
      await this.tracking.track(TrackingEvent.PRICING_PAGE_VIEW);

    }
    catch (error) {
      this.notification.error(this.i18n.t('payment:errors.products_error'));
    }
  })

  /**
   * This createCheckoutSession is used only for NON group products.
   * For group products we use one from 'BuyGroupSubscriptionVm'.
  */
  public createCheckoutSession = new AsyncTask(async (product: StripeProductModel, comingFromInAppBrowser: boolean = false): Promise<void> => {
    await this.tracking.track(TrackingEvent.SUBSCRIPTION_STARTED);

    try {
      const subscriptionAnonymousDto: SubscriptionAnonymousPostRequestDto = {
        productId: product.id,
        priceId: product.price.priceId,
        userId: this.userId,
        email: this.email,
        comingFromInAppBrowser,
        couponId: product.coupon?.id
      };
      const result = await this.stripeProxy.createAnonymousCheckoutSession(subscriptionAnonymousDto);

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      if (!result.ok && result.status === 400) {
        return this.notification.error(this.i18n.t('payment:errors.errors.missing_data'));
      }

      this.notification.error(this.i18n.t('payment:errors.checkout_session_fail'));
    }
    catch (error) {
      console.error(`exception while doing login. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.checkout_session_error'));
    }
  })

}
