export interface IGroupDiscountBulletList {
  description: string;
}

export const discountBulletList: IGroupDiscountBulletList[] = [
  { description: 'group_discount:body.box_two.item_one.list_item_1' },
  { description: 'group_discount:body.box_two.item_one.list_item_2' },
  { description: 'group_discount:body.box_two.item_one.list_item_3' },
  { description: 'group_discount:body.box_two.item_one.list_item_4' },
];

export const groupDiscountBulletList: IGroupDiscountBulletList[] = [
  { description: 'group_discount:body.box_two.item_three.list_item_1' },
  { description: 'group_discount:body.box_two.item_three.list_item_2' },
  { description: 'group_discount:body.box_two.item_three.list_item_3' },
  { description: 'group_discount:body.box_two.item_three.list_item_4' },
];
