/* eslint-disable react/prop-types */
import { t } from 'i18next';
import React, { Ref, useCallback, useImperativeHandle, useState } from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography
} from '@mui/material';

export type DeleteDialogRef = {
  open: () => void,
  close: () => void,
  handleOpenDeleteDialog: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

interface DeleteDialogProps extends React.PropsWithChildren<unknown> {
  locizeNamespace: 'poi' | 'entry' | 'photo' | 'manage_group' | 'paths' | 'subzone' | 'quota_planning';
  onDelete: () => void;
  closeMenu?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const DeleteDialog =
  React.forwardRef<DeleteDialogRef, DeleteDialogProps>(
    function DeleteDialog(props: DeleteDialogProps, ref: Ref<DeleteDialogRef>) {
      const [open, setOpen] = useState<boolean>(false);

      useImperativeHandle(ref, () => ({
        open() {
          setOpen(true);
        },
        close() {
          setOpen(false);
        },
        handleOpenDeleteDialog(event?: React.MouseEvent<HTMLLIElement, MouseEvent>) {
          setOpen(true);
          event?.stopPropagation();
        },
      }));

      const handleCloseDeleteDialog = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setOpen(false);
        if (props.closeMenu) {
          props.closeMenu(event);
        }
      }, [props]);

      return (
        <Dialog open={open} onClose={handleCloseDeleteDialog} sx={{ zIndex: 9999 }}>
          <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
            {t(`${props.locizeNamespace}:delete.title`)}
          </DialogTitle>
          {props.children
            ? props.children
            : <DialogContent>
              <DialogContentText variant="subtitle1">{t(`${props.locizeNamespace}:delete.description`)}</DialogContentText>
            </DialogContent>}
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>
              <Typography variant="subtitle2">{t(`${props.locizeNamespace}:delete.disagree`)}</Typography>
            </Button>
            <Button
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (props.onDelete) {
                  props.onDelete();
                  handleCloseDeleteDialog(event);
                }
              }}
              autoFocus
            ><Typography variant="subtitle2" color={'text.hunter_green'}>{t(`${props.locizeNamespace}:delete.agree`)}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      );
    });
