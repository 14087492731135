import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { UserProfilePutRequestDto, UserResponseDto } from '../../shared/dto';
import { StripeVoucherDto } from '../../shared/dto/stripe/stripe.voucher.dto';
import { GroupSubscriptionResponseDto } from '../../shared/dto/subscription/groupSubscription.response.dto';
import { UserLocationDto } from '../../shared/dto/user.location.dto';
import {
  UserSubscriptionReceiptResponseDto
} from '../../shared/dto/user/user.subscription.receipt.response.dto';
import { AsyncTask } from '../async/AsyncTask';
import { GroupSubscriptionModel } from '../model/GroupSubscriptionModel';
import { StripeVoucherModel } from '../model/stripe/StripeVoucherModel';
import { UserLocationModel } from '../model/UserLocationModel';
import { UserModel } from '../model/UserModel';
import {
  UserSubscriptionReceiptResponseModel
} from '../model/UserSubscriptionReceiptResponseModel';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class UserProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getUser = new AsyncTask(async (userId: string) => {
    const httpResult = await this.ajax.get<UserResponseDto>(`user/${userId}/profile`);
    return this.processGetUserResponse(httpResult);
  });

  public getUserVouchers = new AsyncTask(async () => {
    const httpResult = await this.ajax.get<StripeVoucherDto[]>('user/vouchers');
    if (httpResult.ok) {
      return this.withData(
        httpResult,
        httpResult.data.map((voucher: StripeVoucherDto) => StripeVoucherModel.fromDto(voucher))
      );
    }

    return httpResult;
  });

  public getUserSubscriptionReceipts = async () => {
    const httpResult = await this.ajax.get<UserSubscriptionReceiptResponseDto>('user/subscription-receipts');
    if (httpResult.ok) {
      return this.withData(
        httpResult,
        UserSubscriptionReceiptResponseModel.fromDto(httpResult.data)
      );
    }

    return httpResult;
  }

  public getGroupSubscriptionInfo = new AsyncTask(async (userId: string) => {
    const httpResult = await this.ajax.get<GroupSubscriptionResponseDto>(`user/${userId}/group-subscription`);
    if (httpResult.ok) {
      return this.withData(
        httpResult,
        GroupSubscriptionModel.fromDto(httpResult.data)
      );
    }

    return httpResult;
  });

  public updateUser = new AsyncTask(async (body: UserProfilePutRequestDto) => {
    const httpResult = await this.ajax.put<UserResponseDto>('user/profile', body);
    return this.processGetUserResponse(httpResult);
  });

  public deleteAccount = async () => {
    return await this.ajax.put('user/remove-account');
  }

  public getUserLocation = async (userId: string) => {
    const httpResult = await this.ajax.get<UserLocationDto>(`user/${userId}/location`);
    if (httpResult.ok) {
      return this.withData(httpResult, UserLocationModel.fromDto(httpResult.data));
    }

    return httpResult;
  };

  private processGetUserResponse = (httpResult: IHttpResponse<UserResponseDto>): IHttpResponse<UserModel> => {
    if (httpResult.ok) {
      return this.withData(httpResult, UserModel.fromDto(httpResult.data));
    }

    return httpResult;
  }

}
