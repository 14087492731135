import { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, IconButton, Popover, Typography } from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import {
  shouldEnableDistrictsModal, truncateDistrictsNames
} from '../../../../../util/ManageGroupHelper';

interface AreasColumnProps {
  participant: GroupSubscriptionUserModel;
}

export function AreasColumn({ participant }: AreasColumnProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const shouldEnablePopover = shouldEnableDistrictsModal(participant.districtsNames);

  return (
    <Grid container direction={'row'}>
      <Grid item width={'80%'}>
        <Typography alignSelf={'center'}>{shouldEnablePopover ? truncateDistrictsNames(participant.districtsNames) : participant.districtsNames}</Typography>
      </Grid>
      {shouldEnablePopover &&
        <Grid item width={'10%'} alignSelf={'center'}>
          <IconButton onClick={handleClick} sx={{ p: 0 }}>
            <ArrowDropDownIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 2, maxWidth: 350 }}>{participant.districtsNames}</Typography>
          </Popover>
        </Grid>}
    </Grid>
  );
}
