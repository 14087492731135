import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router';

import { Box } from '@mui/material';

import { useComingFromLocationContext } from '../../domain/context/ComingFromLocationContext';
import { useContainer } from '../../domain/hooks/useContainer';
import { SessionStore } from '../../domain/store/SessionStore';
import { LoadingFallback } from '../../LoadingFallback';
import { appRoutes } from '../../router/routes';
import { LanguageSwitcher } from '../../toolkit/layout/LanguageSwitcher';
import { ResponsiveContainerGrid } from '../../toolkit/layout/ResponsiveContainerGrid';
import { View } from '../../toolkit/layout/View';

export type LocationProps = {
  state: {
    from: Location;
  };
};

export const AnonymousRoute = observer(function AnonymousRoute(props: React.PropsWithChildren<unknown>) {
  const sessionStore = useContainer().get(SessionStore);
  const location = useLocation();
  const { comingFromLocation, setComingFromLocation } = useComingFromLocationContext();

  const fromLocation = useCallback((fromLocation: string) => {
    const existingRoute = Object.values(appRoutes).find(val => val === fromLocation);
    return existingRoute ?? appRoutes.map;
  }, []);

  if (sessionStore.recoverSession.isBusy) {
    return <LoadingFallback />;
  }

  if (sessionStore.isAuthenticated) {
    if (location.state && (location as unknown as LocationProps).state.from) {
      const existingRoute = fromLocation((location as unknown as LocationProps).state.from.pathname);
      setComingFromLocation(existingRoute);
      return <Navigate to={existingRoute} />;
    }

    return <Navigate to={comingFromLocation ?? appRoutes.map} />;
  }

  return (
    <View bgimage="app">
      <Box height={'100%'} display={'flex'} flexDirection={'column'}>
        <Box>
          <LanguageSwitcher />
        </Box>
        <Box
          sx={{
            paddingTop: { xs: 2, sm: 0 },
            paddingBottom: { xs: 2, sm: 0 },
            flexGrow: 1,
          }}>
          <ResponsiveContainerGrid>
            {props.children}
          </ResponsiveContainerGrid>
        </Box>
      </Box>
    </View>
  );
});
