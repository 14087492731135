import { observer } from 'mobx-react-lite';
import React from 'react';
import { useVm } from '../../../../../domain/hooks/useVm';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { Marker } from '../../Marker';
import { EntryTooltip } from '../EntryTooltip';
import { EntryMarkerProps, EntryMarkerVm } from './EntryMarkerVm';

export const EntryMarker = observer(function EntryView(props: EntryMarkerProps) {
  const vm = useVm(EntryMarkerVm, props);

  return (
    <OptionalTooltip enabled={props.tooltipEnabled} arrow placement='top' title={<EntryTooltip vm={vm} />}>
      <Marker
        onClick={() => props.onClick(props.entry)}
        lat={props.lat}
        lng={props.lng}
        icon={vm.entryIcon}
        opacity={props.opacity}
      />
    </OptionalTooltip>
  );
});
